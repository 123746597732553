import React from "react"
import FAQS from '../assets/faq.json';
import LatestNews from "../Components/LatestNews";

export default function FAQ() {
    return (
        <>
            {/* Banner Section */}
            <section className="inner-banner-sec faq-banner-sec" >
                <div className="container custom-container" >
                    <div className="banner-caption text-center">
                        <h1 className="main-heading">Frequently asked <span className="d-block">questions</span></h1>
                    </div>
                </div>
            </section>

            {/* Faq Section */}
            <section className="faqs-sec">
                <div className="accordion container" id="faqAccordion">
                    <h2 className="section-heading text-center" data-aos="fade-up" data-aos-delay="300">The most common questions</h2>
                    <div className="row justify-content-center faqs-row-sec">
                        <div className="col-lg-6 faqs-cols" >
                            {FAQS.section1.map((faq, index) => {
                                return (
                                    <div key={index} className="faqs-support-row accordion-item" data-aos="fade-up" data-aos-delay="300">
                                        <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#faq-section1-${index}`} aria-expanded="true" aria-controls={`faq-section1-${index}`}>
                                            <h3 className="box-heading">{faq.ques}</h3>
                                            <i className="fas fa-angle-double-down"></i>
                                        </div>
                                        <div id={`faq-section1-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#faqAccordion">
                                            <p>
                                                {faq.ans}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                        <div className="col-lg-6 faqs-cols">
                            {FAQS.section2.map((faq, index) => {
                                return (
                                    <div key={index} className="faqs-support-row accordion-item" data-aos="fade-up" data-aos-delay="300">
                                        <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#faq-section2-${index}`} aria-expanded="true" aria-controls={`faq-section2-${index}`}>
                                            <h3 className="box-heading">{faq.ques}</h3>
                                            <i className="fas fa-angle-double-down"></i>
                                        </div>
                                        <div id={`faq-section2-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#faqAccordion">
                                            <p>
                                                {faq.ans}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

            {/* Latest News Sec */}
            <LatestNews />
        </>
    )
}