import React from "react"

export default function CancellationPolicy() {
    return (
        <>
            <section style={{ padding: "3rem 0" }} className="help-section writter-artical-details help-section-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading-innerBanner">
                                <h2 className="section-heading text-center">
                                    <span>Cancellation Policy</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div style={{ border: "1px solid #ddd", padding: "4rem 60px 12px", marginTop: "3rem" }} className="help-box">
                                <div className="help-box-inner2 mt-0">
                                    <div className="single-artical">
                                        <div className="content">
                                            <div className="inner-content">
                                                <h4 className="title">
                                                    1. Subscription Cancellation
                                                </h4>
                                                <p className="text">
                                                    Users have the freedom to cancel their Ant Cloud subscription at any time, for any reason, without incurring any cancellation fees or penalties.
                                                </p>
                                                <h4 className="title">
                                                    2. Active Subscription Period
                                                </h4>
                                                <p className="text">
                                                    Upon cancellation, users' subscriptions will remain active for the remainder of their current subscription period. This means that users will continue to enjoy access to Ant Cloud services until the end of their current billing cycle.                                                </p>
                                                <h4 className="title">
                                                    3. No Refunds for Partial Periods
                                                </h4>
                                                <p className="text">
                                                    Please note that we do not offer refunds or prorated reimbursements for partial subscription periods. Your access to Ant Cloud services will continue until the end of your current billing cycle, regardless of when you choose to cancel.                                                </p>
                                                <h4 className="title">
                                                    4. Account Access
                                                </h4>
                                                <p className="text">
                                                    Even after cancelling your subscription, you will retain access to your Ant Cloud account until the end of your current billing cycle. This allows you to continue enjoying our gaming platform until the conclusion of your subscription period.                                                </p>
                                                <h4 className="title">
                                                    5. Subscription Renewal
                                                </h4>
                                                <p className="text">
                                                    If you choose to re-subscribe to Ant Cloud after cancelling, you can do so at any time. Your account will be reactivated, and you will regain access to our gaming platform upon successful subscription renewal. Otherwise, we delete the data stored in your Virtual PC after a period of 7 days after your subscription has expired.</p>
                                                <h4 className="title">
                                                    6. Cancellation Process
                                                </h4>
                                                <p className="text">
                                                    To cancel your Ant Cloud subscription, simply log in to your account and reach out to our customer support team for assistance with the cancellation process.                                                </p>
                                                <h4 className="title">
                                                    7. Changes to Cancellation Policy
                                                </h4>
                                                <p className="text">
                                                    Ant Cloud reserves the right to modify or update this Cancellation Policy at any time without prior notice. Any changes to the policy will be effective immediately upon posting on our website.                                                </p>
                                                <p className="text">
                                                    By subscribing to Ant Cloud, you acknowledge and agree to abide by the terms outlined in this Cancellation Policy. If you have any questions or require further assistance, please don't hesitate to contact our customer support team.
                                                    Thank you for choosing Ant Cloud for your Cloud Gaming needs!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}