import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/auth/userSlice'
import { authApi } from './services/auth/authService';
import { userApi } from './services/auth/refreshService';
import { logoutApi } from './services/auth/logoutService'

const persistConfig = {
    key: 'root',
    storage,
}

// const rootReducer = combineReducers({
//     auth: authReducer,
//     [authApi.reducerPath]: authApi.reducer,
// })


const persistedReducer = persistReducer(persistConfig, authReducer)

const store = configureStore({
    reducer: {
        auth: persistedReducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [logoutApi.reducerPath]: logoutApi.reducer,
        user: userReducer
    },
    // reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(authApi.middleware).concat(userApi.middleware).concat(logoutApi.middleware)
})

const persistor = persistStore(store);

// export default store
export { store, persistor } 