import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const logoutApi = createApi({
    reducerPath: 'logoutApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.antcloud.co/api/',
        // baseUrl: 'http://localhost:8000/api/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userRefreshToken;
            if(token) {
                headers.set('refresh', `JWT ${token}`);
                return headers;
            }
        },
    }),
    endpoints: (builder) => ({
        logout: builder.query({
            query: () => ({
                url: 'users/logout',
                method: 'GET',
            })
        })
    })
})

export const { useLogoutQuery } = logoutApi;