'use client'
import React from 'react';
import styles from './settingsModal.module.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useEffect, useState, useRef } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MouseIcon from '@mui/icons-material/Mouse';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import GamepadIcon from '@mui/icons-material/Gamepad';
import SettingsIcon from '@mui/icons-material/Settings';
import { ToggleButton } from '@mui/material';
import { useCookies } from 'react-cookie';

function Settings({ keyboardBased, touchAllowed, admin, planEndWarning, timeLeft, rtt, loading, setFps, setBitrate, GpadPositionSaved, setGpadPositionSaved, ToggleOnScreenKeyboard, ToggleOnScreenMouse, toggleGpadOverlay, toggleGpadPosition, FPS, bitrate, setStartStream, setPointerLockAllowed, toggleControls, game }) {
    const qualities = {
        "gareeb": [
            2000,
            4000,
            6000,
            8000
        ],
        "middleClass": [
            10000,
            12000,
            15000,
            18000
        ],
        "ameer": [
            20000,
            25000,
            30000,
            40000
        ]
    }

    const modalRef = useRef(null);
    const modal2Ref = useRef(null);

    //Stream toggle
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [GpadSelected, setGpadSelected] = React.useState(false);
    const [KeyboardSelected, setKeyboardSelected] = React.useState(false);
    const [PositionSelected, setPositionSelected] = React.useState(false);

    modalRef.current = modal;
    modal2Ref.current = modal2;

    useEffect(() => {
        if (keyboardBased) {
            document.addEventListener("keydown", keyHandle, false);
            return () => {
                document.removeEventListener("keydown", keyHandle);
            }
        }
    }, [])

    useEffect(() => {
        if (GpadPositionSaved) {
            if (PositionSelected) {
                toggleGpadPosition()
                setPositionSelected(false);
                setGpadSelected(true)
            }
            setGpadPositionSaved(false)
        }
    }, [GpadPositionSaved])

    //SettingsOverlay
    const toggleOverlay = (event) => {
        if (!modalRef.current) {
            setModal(true);
        } else {
            setModal(false);
        }
    }
    const toggleOverlay2 = (event) => {
        if (!modal2Ref.current) {
            setModal2(true);
        } else {
            setModal2(false);
        }
    }
    const keyHandle = (e) => {
        if (e.code === 'F9') {
            if (e.type === "keydown") {
                if (!modalRef.current) {
                    document.exitPointerLock();
                    setPointerLockAllowed(false);
                    setModal(true);
                } else {
                    setModal(false);
                    setPointerLockAllowed(true);
                }
                if (modal2Ref.current) {
                    setModal2(false);
                }
            }
            return;
        }
    };

    const qualityChange = (event) => {
        setBitrate(event.target.value);
    };
    const fpsChange = (event) => {
        setFps(event.target.value);
    };

    const toggleKeyboard = () => {
        toggleOverlay();
        ToggleOnScreenKeyboard();
    }
    const toggleGpad = () => {
        // console.log("1")
        toggleGpadOverlay();
        toggleOverlay();
    }
    const togglePosition = () => {
        toggleGpadPosition();
        toggleOverlay();
    }

    const theme = createTheme({
        palette: {
            AntGreen: {
                main: '#22B9A6',
                light: '#ffffff',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const [cookies, setCookie, removeCookie] = useCookies([
        "isShutdownClicked",
        "isStreamConnected",
    ]);

    return (
        <ThemeProvider theme={theme}>
            <div>
                {
                    !loading ?
                        keyboardBased ?
                            <div style={{ zIndex: 0 }} className={styles.infooverlay}>
                                Press F9 for <SettingsIcon />
                                {
                                    planEndWarning ?
                                        <p>Your plan is about to end</p>
                                        : null
                                }
                            </div>
                            : <div style={{ zIndex: 99 }}>
                                <div style={{ left: "1%" }} className={styles.infooverlay}>
                                    <button className={styles.iconStyling} onTouchStart={() => { setKeyboardSelected(!KeyboardSelected); ToggleOnScreenKeyboard(); setGpadSelected(false); setPositionSelected(false) }}><KeyboardIcon /></button>
                                    <button className={styles.iconStyling} onTouchStart={() => { setGpadSelected(false); setPositionSelected(false); ToggleOnScreenMouse() }}><MouseIcon /></button>
                                    {/* <KeyboardIcon fontSize='medium' onTouchStart={ToggleOnScreenKeyboard} /> */}
                                </div>
                                <div className={styles.infooverlay}>
                                    <button className={styles.iconStyling} onTouchStart={toggleOverlay}><SettingsIcon /></button>
                                    {
                                        planEndWarning ?
                                            <p>Your plan is about to end</p>
                                            : null
                                    }
                                </div>
                            </div>
                        : null
                }
                <Modal open={modal}>
                    <Box className={styles.settingsBox}>
                        <div className={styles.settings}>
                            {/* {
                                keyboardBased ?
                                    null
                                    : <button className={styles.CloseiconStyling} onClick={toggleOverlay}><CancelIcon /></button>
                            } */}
                            <CancelIcon onClick={() => {
                                setModal(false);
                                setPointerLockAllowed(true)
                            }} fontSize='large' sx={{ position: "absolute", color: "#22B9A6", top: "8%", right: "3%", cursor: "pointer" }} />
                            <div className="ModalRow">
                                <div className="ModalColumn">
                                    <div className={styles.SteamDataStyling}>
                                        <h3>Stream Data</h3>
                                        <p>Ping: {rtt} ms</p>
                                        <p>Time Left: {timeLeft} minutes</p>
                                    </div>
                                </div>
                                <div className="ModalColumn">
                                    <div className={styles.VidSettingsStyling}>
                                        <h3 className={styles.SettingHeading}>Video Settings</h3>
                                        <div>
                                            <FormControl >
                                                <FormLabel sx={{
                                                    color: "#fff",
                                                    '&.Mui-focused': {
                                                        color: "#fff",
                                                    },
                                                }}>FPS</FormLabel>
                                                <RadioGroup
                                                    row
                                                    onChange={fpsChange}
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={FPS}
                                                    theme={theme}
                                                    sx={{ color: 'AntGreen.light' }}
                                                >
                                                    <FormControlLabel value={30} control={<Radio size="small" sx={{
                                                        color: "#fff",
                                                        '&.Mui-checked': {
                                                            color: "#22B9A6",
                                                        },
                                                    }} />} label="30 FPS" />
                                                    <FormControlLabel value={60} control={<Radio size="small" sx={{
                                                        color: "#fff",
                                                        '&.Mui-checked': {
                                                            color: "#22B9A6",
                                                        },
                                                    }} />} label="60 FPS" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="ModalColumn">
                                    <div className={styles.StreamQualityStyling}>
                                        <h3>Stream Quality</h3>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={bitrate}
                                            label="Quality"
                                            onChange={qualityChange}
                                            theme={theme}
                                            color='AntGreen'
                                            sx={{ backgroundColor: 'AntGreen.light' }}
                                        >
                                            <MenuItem value={2000}>Low</MenuItem>
                                            <MenuItem value={6000}>Medium</MenuItem>
                                            <MenuItem value={12000}>High</MenuItem>
                                            {bitrate !== 2000 && bitrate !== 6000 && bitrate !== 12000 ? <MenuItem selected value={bitrate}> {bitrate} </MenuItem> : <></>}
                                        </Select>
                                        <button className={styles.ADSettings} onClick={toggleOverlay2}>Advanced Settings</button>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <button
                                    style={{
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        padding: '11px',
                                        alignItems: 'center'
                                    }}
                                    className={styles.iconStyling}
                                    onClick={() => {
                                        setStartStream(false)
                                        if (touchAllowed) window.Android.showMessageInNative('ended')
                                        if (game === "desktop") {
                                            const currentTime = new Date().getTime();
                                            setCookie("isShutdownClicked", currentTime.toString(), {
                                                path: "/",
                                            });
                                        }
                                        removeCookie("isStreamConnected", { path: "/" });
                                    }}
                                >
                                    <PowerSettingsNewIcon fontSize='medium' />
                                    <p>Shut Down PC</p>
                                </button>
                                {
                                    admin ?
                                        <button
                                            style={{
                                                display: 'inline-flex',
                                                flexDirection: 'column',
                                                padding: '11px',
                                                alignItems: 'center'
                                            }}
                                            className={styles.iconStyling}
                                            onClick={toggleControls}
                                        >
                                            <p>Toggle Controls</p>
                                        </button>
                                        : null
                                }
                                {
                                    touchAllowed ?
                                        <>
                                            {/* <ToggleButton
                                                onClick={toggleKeyboard}
                                                theme={theme}
                                                sx={{ flexDirection: 'column', color: '#22B9A6', backgroundColor: 'transparent', border: 'none', '&.Mui-selected': { color: '#22B9A6', backgroundColor: '#595959' }, '&.Mui-selected:hover': { color: '#22B9A6', backgroundColor: '#595959' } }}
                                                value="check"
                                                selected={KeyboardSelected}
                                                onChange={() => {
                                                    setKeyboardSelected(!KeyboardSelected);
                                                    setGpadSelected(false)
                                                    setPositionSelected(false)
                                                }}
                                            >
                                                <KeyboardIcon fontSize='medium' />
                                                <p>Keyboard</p>
                                            </ToggleButton> */}
                                            <ToggleButton
                                                // onClick={!PositionSelected ? toggleGpad : null}
                                                onClick={!PositionSelected ? () => {
                                                    toggleGpad()
                                                    setGpadSelected(!GpadSelected);
                                                    setPositionSelected(false)
                                                    setKeyboardSelected(false)
                                                } : null}
                                                theme={theme}
                                                sx={{ flexDirection: 'column', color: '#22B9A6', backgroundColor: 'transparent', border: 'none', '&.Mui-selected': { color: '#22B9A6', backgroundColor: '#595959' }, '&.Mui-selected:hover': { color: '#22B9A6', backgroundColor: '#595959' } }}
                                                value="check"
                                                selected={GpadSelected}
                                            // onChange={() => {
                                            //     setGpadSelected(!GpadSelected);
                                            //     setPositionSelected(false)
                                            //     setKeyboardSelected(false)
                                            // }}
                                            >
                                                <SportsEsportsIcon fontSize='medium' />
                                                <p>Gamepad</p>
                                            </ToggleButton>
                                            <ToggleButton
                                                // onClick={togglePosition}
                                                onClick={PositionSelected ? () => {
                                                    togglePosition()
                                                    setPositionSelected(!PositionSelected);
                                                    setGpadSelected(true)
                                                } : () => {
                                                    togglePosition()
                                                    setPositionSelected(!PositionSelected);
                                                    setGpadSelected(false)
                                                    setKeyboardSelected(false)
                                                }
                                                }
                                                theme={theme}
                                                sx={{ flexDirection: 'column', color: '#22B9A6', backgroundColor: 'transparent', border: 'none', '&.Mui-selected': { color: '#22B9A6', backgroundColor: '#595959' }, '&.Mui-selected:hover': { color: '#22B9A6', backgroundColor: '#595959' } }}
                                                value="check"
                                                selected={PositionSelected}
                                            // onChange={() => {
                                            //     setPositionSelected(!PositionSelected);
                                            //     setGpadSelected(false)
                                            //     setKeyboardSelected(false)
                                            // }}
                                            >
                                                < GamepadIcon fontSize='medium' />
                                                <p>Edit Layout</p>
                                            </ToggleButton>
                                        </>
                                        : null
                                }
                            </div>
                            {
                                touchAllowed ?
                                    null :
                                    <p style={{ color: "#22B9A6", bottom: "10%", position: "absolute" }}>
                                        <br /> F10 : Toggle FullScreen
                                        <br /> F8 : toggle Pointer Lock
                                    </p>
                            }
                        </div>

                    </Box>
                </Modal>
                <Modal open={modal2}>
                    <Box className={styles.settingsBox}>
                        <div className={styles.settingsModal2}>
                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                <button className={styles.CloseiconStyling} onClick={toggleOverlay2}><CancelIcon /></button>
                                <div>
                                    <h3>Data Saving Mode</h3>
                                    <ul>
                                        {qualities.gareeb.map((gareebQuality) => {
                                            return (
                                                <li className={styles.ADModes} key={gareebQuality}>
                                                    <button
                                                        onClick={() => {
                                                            setBitrate(gareebQuality)
                                                        }}
                                                        style={
                                                            bitrate === gareebQuality ?
                                                                { backgroundColor: "transparent", color: "#22B9A6" }
                                                                : { backgroundColor: "transparent", color: "#fff" }
                                                        }
                                                    >{gareebQuality} Kbps</button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div >
                                    <div className={styles.qualityCenterDiv}>
                                        <h3 >Performance Mode</h3>
                                        <ul>
                                            {qualities.middleClass.map((middleClassQuality) => {
                                                return (
                                                    <li className={styles.ADModes} key={middleClassQuality}>
                                                        <button
                                                            onClick={() => setBitrate(middleClassQuality)}
                                                            style={
                                                                bitrate === middleClassQuality ?
                                                                    { backgroundColor: "transparent", color: "#22B9A6" }
                                                                    : { backgroundColor: "transparent", color: "#fff" }
                                                            }
                                                        >{middleClassQuality} Kbps</button>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <h3>Quality Mode</h3>
                                    <ul>
                                        {qualities.ameer.map((ameerQuality) => {
                                            return (
                                                <li className={styles.ADModes} key={ameerQuality}>
                                                    <button
                                                        onClick={() => setBitrate(ameerQuality)}
                                                        style={
                                                            bitrate === ameerQuality ?
                                                                { backgroundColor: "transparent", color: "#22B9A6" }
                                                                : { backgroundColor: "transparent", color: "#fff" }
                                                        }
                                                    >{ameerQuality} Kbps</button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </ThemeProvider>
    );
}

export default Settings;