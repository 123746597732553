import React from "react"
import joinImg from '../images/join-img.webp'
// import joinImg from '../images/Stockedit.png'
import shapeImg from '../images/shape.webp'
import blog1 from '../images/blog1.png'
import blog2 from '../images/blog2.png'
import { Link, useNavigate } from "react-router-dom"

export default function LatestNews({ showNews }) {
    const navigate = useNavigate();
    return (
        <section className="latest-newH-sec">
            <div className={showNews ? "container-inn" : ""}>
                <div className="container custom-container">
                    {showNews ? <>
                        <div className="latest-newH-heading" data-aos="fade-up" data-aos-delay="340">
                            <h2 className="section-heading">Latest news</h2>
                            <a href="/blogdashboard" className="btn-comm">View All</a>
                        </div>
                        <div className="row">
                            <div
                                onClick={() => navigate(`/blogdetail/1`)}
                                className="col-lg-6 latest-newsH-box"
                                data-aos="fade-up"
                                data-aos-delay="380"
                            >
                                <div className="latest-newsH-boxInn">
                                    <img src={blog1} className="blogH-img" alt="" />
                                    <div className="latest-newH-content">
                                        <h5 className="blogH-date">April 05, 2023</h5>
                                        <h3
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                            className="box-heading"
                                        >
                                            Revolutionising Video Editing: The Impact of Cloud
                                            Computing, Featuring Ant Cloud
                                        </h3>
                                        <p className="blogContent">
                                            In the digital era, where content creation reigns
                                            supreme, the demands for high-quality video editing are
                                            escalating rapidly. From amateur vloggers to
                                            professional filmmakers, everyone seeks seamless editing
                                            processes that not only save time but also elevate the
                                            quality of their creations.
                                        </p>
                                        <a href="/blogdetail/1" className="btn-text">
                                            View More <i className="fas fa-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                onClick={() => navigate(`/blogdetail/2`)}
                                className="col-lg-6 latest-newsH-box"
                                data-aos="fade-up"
                                data-aos-delay="400"
                            >
                                <div className="latest-newsH-boxInn">
                                    <img src={blog2} className="blogH-img" alt="" />
                                    <div
                                        // style={{
                                        //   overflow: "hidden",
                                        // }}
                                        className="latest-newH-content"
                                    >
                                        <h5 className="blogH-date">May 02, 2024</h5>
                                        <h3
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                            className="box-heading"
                                        >
                                            The Influence of Gaming on Popular Culture
                                        </h3>
                                        <p className="blogContent">
                                            In recent years, the gaming industry has witnessed a
                                            revolutionary shift with the emergence of cloud gaming
                                            services like Ant Cloud alongside traditional gaming
                                            methods. This transition has sparked debates among
                                            gamers worldwide, each advocating for their preferred
                                            mode of gaming
                                        </p>
                                        <a href="/blogdetail/2" className="btn-text">
                                            View More <i className="fas fa-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-6 latest-newsH-box" data-aos="fade-up" data-aos-delay="380">
                                <div className="latest-newsH-boxInn">
                                    <img src={blogHImg} className="blogH-img" alt="Blog-BG" />
                                    <div className="latest-newH-content">
                                        <h5 className="blogH-date">July 10, 2023</h5>
                                        <h3 className="box-heading">The Influence of Gaming on Popular Culture</h3>
                                        <p>In this blog post, we will take a deep dive into the world of competitive gaming</p>
                                        <a href="#" className="btn-text">View More <i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 latest-newsH-box" data-aos="fade-up" data-aos-delay="400">
                                <div className="latest-newsH-boxInn">
                                    <img src={blogHImg2} className="blogH-img" alt="Blog-BG" />
                                    <div className="latest-newH-content">
                                        <h5 className="blogH-date">July 10, 2023</h5>
                                        <h3 className="box-heading">The Influence of Gaming on Popular Culture</h3>
                                        <p>In this blog post, we will take a deep dive into the world of competitive gaming</p>
                                        <a href="#" className="btn-text">View More <i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </> : <></>}

                    <div className="row join-us-sec">
                        <div className="col-lg-7 join-us-content" data-aos="fade-up" data-aos-delay="340">
                            <h2 className="section-heading">Join the gaming revolution today</h2>
                            <p>
                                Click below to take the first step towards an immersive gaming journey
                            </p>
                            <a href="https://discord.gg/fZRwkKwZQB" target="_blank" rel="noopener noreferrer" className="btn-comm btn-lg">Join our Discord now</a>
                        </div>
                        <div className="col-lg-5 pointsV-home-img" data-aos="zoom-out" data-aos-delay="340">
                            <img src={joinImg} className="img-fluid" alt="Kid Playing On VR" />
                            <div className="x animated-shapes">
                                <img src={shapeImg} className="img-shape y" alt="Background Oval Shape" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}