import React, { useRef } from "react"
import { Link } from "react-router-dom"
import NotFoundImg from '../images/404.webp';
// import NotFoundGif from '../videos/NotFound.gif';

export default function NotFound() {
    return (
        <>
            {/* <section className="four-zero-four" style={{ height: "60vh" }}>
            <img style={{ width: "100%", position: "absolute", paddingTop:"15rem" }} src={NotFoundGif} alt="" />
            <Link to="/" class="mybtn1"><i class="fas fa-angle-double-left"></i> BACK TO HOME</Link>

        </section> */}
            <section class="four-zero-four" id="home">
                <img class="bg-img" src="https://antplay.tech/extra/assets/images/404-bg.png" alt="" />
                <div style={{ textAlign: "center" }}>
                    <img src={NotFoundImg} alt="" />
                    <h2 style={{ color: "#fff", marginTop: "10rem" }} class="title">
                        Oops, Page Not found!
                    </h2>
                    <Link className="NotFoundBtn" to="/" style={{ marginTop: "5rem", background: "#22b9a6", color: "#fff", padding: "1rem 2rem", borderRadius: "30px", position: "absolute", bottom: "35%", right: "43%" }}><i class="fas fa-angle-double-left"></i> BACK TO HOME</Link>
                </div>
                {/* <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="content" style={{ textAlign: "center" }}>
                                <img src="https://antplay.tech/extra/assets/images/404.png" alt="" />
                                <div class="inner-content">
                                    <h4 class="title">
                                        Oops,
                                        Something went wrong !
                                    </h4>
                                    <Link to="/" class="mybtn1" style={{ display: "block", bottom: "0" }}><i class="fas fa-angle-double-left"></i> BACK TO HOME</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}