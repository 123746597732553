import React from "react";

export default function Instructions () {
  return (
    <div>
      {" "}
      <section className="inner-banner-sec support-banner-sec">
        <div className="" data-aos="fade-up" data-aos-delay="300">
          <div className="container custom-container">
            <div
              style={{ maxWidth: "100%" }}
              className="banner-caption  text-center"
            >
              <h1 className="main-heading main-heading-md">
                Windows app instructions
              </h1>
              {/* <p className="subHeading-text">Duis at volutpat orci facilisis risus nunc consequat dui volutpat et. </p> */}
            </div>
          </div>
        </div>
      </section>
      <div
        style={{
          margin: "auto",
          height: "100%",
          width: "100%",
          background: "#171717",
          color: "white",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "auto",
            // background: "#1e1e1e",
            color: "white",
            padding: "30px",
            borderRadius: "10px",
            // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: "#22b9a6",
            }}
          >
            Welcome to the Step-by-Step Guide for our Windows App!
          </h2>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "1.6",
              marginBottom: "20px",
            }}
          >
            Please follow the instructions below to access your virtual PC using
            the Windows app:
          </p>
          <ol style={{ marginBottom: "0px", paddingLeft: "20px" }}>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                1
              </span>
              <span>
                Make sure you have downloaded & installed the Windows app via{" "}
                <a
                  href="https://antplay-client-1f85c53a.s3.ap-south-1.amazonaws.com/windows/Antcloud.msi"
                  style={{ color: "#00aced", textDecoration: "underline" }}
                >
                  this link
                </a>
                .
              </span>
            </li>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                2
              </span>
              <span>
                Make sure you have downloaded & installed the dependency required for windows app via{" "}
                <a
                  href="https://download.visualstudio.microsoft.com/download/pr/0bfb4b48-9221-491f-8157-eed2307f13e6/3d7890b36ae32759d141633afd43787e/windowsdesktop-runtime-6.0.35-win-x64.exe"
                  style={{ color: "#00aced", textDecoration: "underline" }}
                >
                  this link
                </a>
                .
              </span>
            </li>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                2
              </span>
              <span>
                Once you have installed the app, please go to{" "}
                <a
                  href="stream"
                  style={{ color: "#00aced", textDecoration: "underline" }}
                >
                  this link
                </a>{" "}
                to access your virtual PC via the app. Please do not try to open
                the app on its own as it only works in tandem with our website.
              </span>
            </li>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                3
              </span>
              <span>
                On the Windows app Dashboard page, click on the power button.
              </span>
            </li>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                4
              </span>
              <span>
                Once you do, your PC will start & it can take upto 5 minutes to
                start the PC
              </span>
            </li>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                5
              </span>
              <span>
                Once done, you will be prompted to launch the Windows App. You
                can change your resolution, FPS & bitrate before launching
              </span>
            </li>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                6
              </span>
              <span>
                Once you click on launch PC, you will be prompted to "Leave the
                App" & once you select that you will be prompted to "Launch
                AntCloud" via your browser.{" "}
              </span>
            </li>
            <li
              style={{
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                7
              </span>
              <span>
                Once you accept the prompts, your Windows App will launch
                automatically{" "}
              </span>
            </li>
            <li
              style={{
                // paddingBottom: "10px",
                display: "flex",
                //
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                8
              </span>
              <span>
                To close the app please press on "Ctrl + F9" to
                close the app. Once done, you can close your VM via the Shut Down PC
                button or simply close the tab.
              </span>
            </li>
          </ol>
          <div
            style={{
              maxWidth: "800px",
              margin: "auto",
              marginTop:"10px",
              background: "#1e1e1e",
              color: "white",
              padding: "30px",
              borderRadius: "10px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <h3>Shortcuts for the app:</h3>
            <li
              style={{
                // paddingBottom: "10px",
                display: "flex",
                textAlign: "center",
                
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                1
              </span>
              <span>
                To disconnect the session/close the app: "Ctrl + F9"
              </span>
            </li> <li
              style={{
                // paddingBottom: "10px",
                display: "flex",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "21px",
                  marginRight: "10px",
                  color: "#22b9a6",
                }}
              >
                2
              </span>
              <span>
              To exit/enter Fullscreen the application: "Ctrl + F10"
              </span>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};