import React, { useEffect, useRef, useState } from "react";
import Controller from "../images/controller_mods/controller.png";
import GamepadButtons from "../Components/ControllerMapping/GamepadButtons";
import { defaultGamepadMapping1 } from "../Components/ControllerMapping/GamePadButton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getGamePadMapping, saveGamePadMapping } from "../api/gamepad";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

const ControllerMapper = () => {

  const basicDeniedStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    ["@media (min-width:992px)"]: {
      width: "40%",
    },
    // width: 600,
    color: "#fff",
    bgcolor: "#212121",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const type = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [gamepadIndex, setGamepadIndex] = useState(null);
  const [gamepadName, setGamepadName] = useState("");
  const [gamepadInputs, setGamepadInputs] = useState(defaultGamepadMapping1);
  const [buttonsPressed, setButtonsPressed] = useState();
  const [buttonsPressed1, setButtonsPressed1] = useState();
  const [gamePadConnected, setGamePadConnected] = useState([]);
  const [goBack, setGoBack] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const { loggedIn, userToken } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  // let gamepadData = JSON.parse(localStorage.getItem("gamepads"));
  const defaultGamePadSaving = localStorage.getItem("gamepads")
  let gamepadData = defaultGamePadSaving !== 'undefined' ? JSON.parse(defaultGamePadSaving) : [];
  const gamePadFetched = (e) => {
    localStorage.setItem("gamepads", JSON.stringify(e.gamepad));
    gamepadData = e.gamepad;
  };

  const handleGoBack = () => {
    if (window.Android) {
      window.Android.showMessageInNative('ended');
    }
  }



  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paramIdToken = params.get('idToken');
    type.current = params.get('type');
    setIdToken(paramIdToken);
    if (!loggedIn && type.current !== "mobile") {
      toast.error("You need to login to access this page!")
      navigate('/signin')
    } else if (type.current === "mobile" && !paramIdToken) {
      toast.error("Something went wrong, Please restart your app once!", {
        autoClose: false
      })
      setGoBack(true)
    } else {
      let tokenn;
      if (type.current === "mobile") tokenn = paramIdToken;
      else tokenn = userToken
      getGamePadMapping(tokenn, gamePadFetched, () => { setGoBack(true) });
    }

    const handleGamepadConnected = (event) => {
      // console.log("Gamepad connected:", event.gamepad);
      setGamepadName(event.gamepad.id);
      setGamepadIndex(event.gamepad.index);
      setGamePadConnected((prev) => {
        if (prev.length > 0) {
          setModalOpen(true);
        } else if (gamepadData?.length > 0) {
          const gamepadFound = gamepadData.find(
            (item) => item.name === event.gamepad.id
          );
          // console.log(gamepadFound);
          if (gamepadFound) {
            const temp = defaultGamepadMapping1.map((item) => {
              const a = gamepadFound.buttonMapping[item.key];
              if (['LJX', "LJY", "RJX", "RJY"].includes(item.key)) {
                return {
                  ...item,
                  keycode: defaultGamepadMapping1[a].keycode + 16,
                  key: item.key,
                };
              }
              else {
                return {
                  ...item,
                  keycode: defaultGamepadMapping1[a].keycode,
                  key: defaultGamepadMapping1[a].key,
                };
              }
            });
            // console.log(temp);
            // console.log(temp)
            setGamepadInputs(temp);
          }
        }
        return [
          ...prev,
          { name: event.gamepad.id, index: event.gamepad.index },
        ];
      });
    };

    const handleGamepadDisconnected = (event) => {
      setModalOpen(false);
      // console.log("Gamepad disconnected:", event.gamepad);
      setGamepadName();
      setGamepadIndex(null);
      setGamePadConnected((prev) => {
        for (let i = 0; i < prev.length; i++) {
          if (prev[i].index !== event.gamepad.index) {
            handleGamepadClick(prev[i]);
            break;
          }
        }
        return prev.filter((pad) => pad.index !== event.gamepad.index);
      });
    };

    window.addEventListener("gamepadconnected", handleGamepadConnected);
    window.addEventListener("gamepaddisconnected", handleGamepadDisconnected);

    return () => {
      window.removeEventListener("gamepadconnected", handleGamepadConnected);
      window.removeEventListener(
        "gamepaddisconnected",
        handleGamepadDisconnected
      );
    };
  }, []);
  // console.log(gamePadConnected);
  const handleGamepadClick = (e) => {
    // console.log(e);
    gamepadData = JSON.parse(localStorage.getItem("gamepads"));
    setGamepadName(e.name);
    setGamepadIndex(e.index);
    setModalOpen(false);
    const gamepadFound = gamepadData.find((item) => item.name === e.name);
    // console.log(gamepadFound);
    if (gamepadFound) {
      // console.log("first");
      const temp = defaultGamepadMapping1.map((item) => {
        // console.log(item);
        // console.log(gamepadFound.buttonMapping);
        const a = gamepadFound.buttonMapping[item.key];
        return {
          ...item,
          keycode: defaultGamepadMapping1[a].keycode,
          key: defaultGamepadMapping1[a].key,
        };
      });
      setGamepadInputs(temp);
    } else setGamepadInputs(defaultGamepadMapping1);
  };
  // console.log(gamepadInputs, gamepadName, gamepadIndex);
  useEffect(() => {
    if (buttonsPressed || buttonsPressed == 0) {
      if (buttonsPressed1) {
        if (
          (buttonsPressed1 === "Left Joystick X-Axis" ||
            buttonsPressed1 === "Right Joystick Y-Axis" ||
            buttonsPressed1 === "Left Joystick Y-Axis" ||
            buttonsPressed1 === "Right Joystick X-Axis") &&
          buttonsPressed !== 16 &&
          buttonsPressed !== 17 &&
          buttonsPressed !== 18 &&
          buttonsPressed !== 19
        ) {
          toast.error("Can't map buttons to axis");
        } else {
          const temp = defaultGamepadMapping1.find(
            (e) => e.keycode === buttonsPressed
          );
          // console.log(temp);
          if (temp) {
            let temp2 = gamepadInputs.map((item) =>
              item.name === temp.name ? { ...item, keycode: -1, key: "" } : item
            );
            temp2 = gamepadInputs.map((item) =>
              item.keycode === temp.keycode
                ? { ...item, keycode: -1, key: "" }
                : item
            );
            const temp1 = temp2.map((item) =>
              item.name === buttonsPressed1
                ? { ...item, keycode: buttonsPressed, key: temp.key }
                : item
            );
            setGamepadInputs(temp1);
          }
          setButtonsPressed1();
        }
      }
    }
  }, [buttonsPressed]);

  const saveControllerInputs = () => {
    let finalInputs = [];
    for (let i = 0; i < gamepadInputs.length; i++) {
      if (!gamepadInputs[i].key) {
        toast.error("Some inputs are missing");
        finalInputs = [];
        return;
      } else {
        const keyFound = defaultGamepadMapping1.find(
          (e) => e.name === gamepadInputs[i].name
        );
        if (['LJX', "LJY", "RJX", "RJY"].includes(keyFound.key)) {
          finalInputs.push({
            [keyFound.key]: gamepadInputs[i].keycode - 16,
          });
        }
        else {
          finalInputs.push({
            [keyFound.key]: gamepadInputs[i].keycode,
          });
        }

      }
    }
    if (finalInputs.length !== 0) {
      const buttonMapping = finalInputs.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});
      let tokenn;
      if (idToken) tokenn = idToken;
      else tokenn = userToken;
      // for
      saveGamePadMapping(tokenn, {
        name: gamepadName,
        buttonMapping: buttonMapping,
      });
    }
  };
  return (
    <>
      {loggedIn || !goBack
        ? <>
          {gamePadConnected.length > 1 && (
            <div
              style={{
                margin: "10px 0",
                maxWidth: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControl style={{ maxWidth: "500px", width: "100% " }}>
                <InputLabel id="demo-simple-select-label">
                  Select your controller
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gamepadName}
                  label="Age"
                  style={{ maxWidth: "500px", width: "100% " }}
                // onChange={handleChange}
                >
                  {gamePadConnected.map((e) => (
                    <MenuItem
                      onClick={() => handleGamepadClick(e)}
                      style={{ maxWidth: "500px", width: "100% " }}
                      value={e.name}
                    >
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="controllermapping">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="controllerImage"
                style={{
                  backgroundImage: `url(${Controller})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  // backgroundPosition: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {buttonsPressed !== undefined ? (
                  <img
                    src={
                      defaultGamepadMapping1.find(
                        (m) => m.keycode === buttonsPressed
                      )?.img
                    }
                    alt="Gamepad Button"
                  />
                ) : buttonsPressed1 ? (
                  <img
                    src={
                      defaultGamepadMapping1.find((m) => m.name === buttonsPressed1)
                        ?.img
                    }
                    alt="Gamepad Button"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* <img style={{ maxWidth: "500px" }} src={Controller} alt="controller" /> */}
            {/* <p>
        {gamepadIndex !== null
          ? `Gamepad connected at index ${gamepadIndex}`
          : "No gamepad connected"}
      </p> */}
            {gamepadName ? (
              <div style={{ width: "100%", background: "#d3d3d3", height: "100vh", overflowY: "auto", overflowX: "hidden" }}>
                <p style={{ textAlign: "center", paddingTop: "2rem" }}>Press Save button below</p>
                <div>
                  {gamepadIndex !== null && (
                    <GamepadButtons
                      setButtonsPressed={setButtonsPressed}
                      buttonsPressed={buttonsPressed}
                      gamepadIndex={gamepadIndex}
                    />
                  )}
                  <div className="controllerbutton">
                    {gamepadInputs.map((m, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={() => setButtonsPressed1(m.name)}
                          style={
                            buttonsPressed1 === m.name
                              ? {
                                display: "flex",
                                width: "100%",
                                background: "#6DF728",
                                cursor: "pointer",
                                alignItems: "center",
                                padding: "10px 10px",
                                borderRadius: "5px",
                                justifyContent: "space-between",
                              }
                              : !m.key
                                ? {
                                  display: "flex",
                                  width: "100%",
                                  background: "red",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  padding: "10px 10px",
                                  borderRadius: "5px",
                                  justifyContent: "space-between",
                                }
                                : buttonsPressed == m.keycode
                                  ? {
                                    display: "flex",
                                    width: "100%",
                                    background: "blue",
                                    cursor: "pointer",
                                    padding: "10px 10px",
                                    borderRadius: "5px",
                                    justifyContent: "space-between",
                                  }
                                  : {
                                    display: "flex",
                                    width: "100%",
                                    cursor: "pointer",
                                    padding: "10px 10px",
                                    borderRadius: "5px",
                                    justifyContent: "space-between",
                                  }
                          }
                        >
                          <div style={{ userSelect: "none" }}>{m.name}</div>

                          <div
                            style={{
                              userSelect: "none",
                              background: "gray",
                              padding: "4px",
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              borderRadius: "4px",
                            }}
                          >
                            {m.key}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    onClick={saveControllerInputs}
                    style={{
                      background: "#2876F7",
                      padding: "10px 30px",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    Save
                  </button>
                </div>{" "}
              </div>
            ) : (
              <div>
                <h1>Press any key to start</h1>
              </div>
            )}
          </div>
          <Modal
            open={modalOpen}
            style={{ color: "white" }}
            // onClose={() => setModalOpen(false)}
            className="ComingSoon-modal"
          >
            <Box sx={basicDeniedStyle}>
              <Typography
                sx={{ textAlign: "center", marginBottom: "20px !important" }}
                variant="p"
              >
                Oops! It seems you have connected more than one controller. Please
                select any one to continue
              </Typography>
              <br /> <br />
              {gamePadConnected.map((e) => (
                <p
                  onClick={() => handleGamepadClick(e)}
                  style={{
                    border: "2px solid",
                    padding: "2px 10px",
                    cursor: "pointer",
                  }}
                >
                  {e.name}
                </p>
              ))}
            </Box>
          </Modal>
        </>
        : <div style={{ height: "100vh", background: "#111", textAlign: "center", color: "#fff", paddingTop: "8rem" }}>
          {
            goBack
            && <>
              <h6>Something went wrong</h6>
              <button className="btn-comm btn-lg-2 btnNext mobile-btn-cta" style={{ marginTop: "3rem" }} onClick={handleGoBack}>Go Back</button>
            </>
          }
        </div>}


    </>
  );
};

export default ControllerMapper;
