import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import styles from './dashboard.module.css';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import bgImage from '../../images/PCMode.webp'
import profileStyle from "./profile.module.scss";
import AccountDetails from "../account-details/AccountDetails";
import BillingDetails from "../billing-details/BillingDetails";
import Miscellaneous from "../game-settings/Miscellaneous";
import ComingSoon from '../../images/ComingSoon.webp';
import dashboardImg from '../../images/DashboardImg.webp';
import gameImg from '../../images/OurGames.webp';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Cancel, Close } from '@mui/icons-material';
import Settings from '../stream/overlays/settingsModal';
import { zoom } from '../zoom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { PowerButton } from '../PowerButton';
import { useCookies } from 'react-cookie';

export function WindowsDashboard({ maintenance, games, stream }) {

  const navigate = useNavigate();
  const gamesRef = useRef(null);
  gamesRef.current = gamesRef;
  const dispatch = useDispatch();
  const { loggedIn, userInfo } = useSelector((state) => state.auth);
  const user = useSelector(state => state.user.userDetails);

  // Modal
  const [comingSoon, setComingSoon] = useState(false);
  const handleOpen = () => setComingSoon(true);
  const handleClose = () => setComingSoon(false);

  const [basicDenied, setBasicDenied] = useState(false);
  const handleBasicDeniedClose = () => setBasicDenied(false);

  const [maintenanceModal, setMaintenanceModal] = useState(false);
  const handleMaintenanceModalClose = () => setMaintenanceModal(false);

  const [emailDenied, setEmailDenied] = useState(false);
  const handleEmailDeniedClose = () => setEmailDenied(false);

  const [startDisabled, setStartDisabled] = useState(false);
  const [openStartDisabled, setOpenStartDisabled] = useState(false);
  const [tutorialModal, setTutorialModal] = useState(false);
  const location = useLocation();
  const handleOpenStartDisabledClose = () => setOpenStartDisabled(false);

  const [playButton, setPlayButton] = useState(true);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    '&:focus-visible': {
      outline: "none"
    }
  };

  const basicDeniedStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    ["@media (min-width:992px)"]: {
      width: "40%"
    },
    // width: 600,
    color: "#fff",
    bgcolor: '#212121',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const basicDeniedStyle1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    ":focus-visible": {
      "outline": "none"
    },
    ["@media (min-width:992px)"]: {
      width: "40%"
    },
    // width: 600,
    color: "#fff",
    bgcolor: '#212121',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center"
  };

  const tutorialStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    height: "50%",
    // width: 600,
    color: "#fff",
    bgcolor: '#212121',
    border: '2px solid #000',
    '&:focus-visible': {
      outline: "none"
    },
    boxShadow: 24,
    p: 4,
  };

  // Profile
  const [menuItem, setMenuItem] = useState("Account");
  const [menuOpen, setMenuOpen] = useState(false);

  const content = {
    Account: <AccountDetails />,
    Billing: <BillingDetails />,
    // Settings: <Miscellaneous />,
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [menuItem]);

  // const checkAppInstalled = (callback) => {
  //   const iframe = document.createElement('iframe');
  //   iframe.style.display = 'none';
  //   iframe.src = 'Antcloud:open'; // Replace with your app's URI scheme
  
  //   document.body.appendChild(iframe);
  
  //   let timeout;
  
  //   const onIframeLoad = () => {
  //     clearTimeout(timeout);
  //     document.body.removeChild(iframe);
  //     callback(true); // App is installed
  //   };
  
  //   const onIframeError = () => {
  //     clearTimeout(timeout);
  //     document.body.removeChild(iframe);
  //     callback(false); // App is not installed
  //   };
  
  //   iframe.onload = onIframeLoad;
    
  //   // Timeout after 1 second to check if app responded
  //   timeout = setTimeout(onIframeError, 1000);
  // };

  // const handleCheckAppInstalled = () => {
  //   checkAppInstalled((isInstalled) => {
  //     if (isInstalled) {
  //       alert('App is installed');
  //       // Only now you can launch the app
  //       // window.location.href = 'myapp://open';
  //     } else {
  //       alert('App is not installed');
  //       // Show fallback or redirect to download page
  //     }
  //   });
  // };
  
  const [isInstalled, setIsInstalled] = useState(null);

  const checkAppInstalled = () => {
    let isAppOpened = false;

    // Save the current time
    const currentTime = new Date().getTime();

    // Add event listener for focus change
    const focusListener = () => {
      isAppOpened = true;
    };
    window.addEventListener('blur', focusListener);

    // Attempt to open the app using a custom URI scheme
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = 'Antcloud://open'; // Replace with your app's custom URI scheme
    document.body.appendChild(iframe);

    // Set a timeout to check whether the app was opened or not
    setTimeout(() => {
      window.removeEventListener('blur', focusListener);
      document.body.removeChild(iframe);

      const timeSinceAttempt = new Date().getTime() - currentTime;

      // If the app was opened (blur detected) or enough time has passed, assume the app was installed
      if (isAppOpened && timeSinceAttempt < 2000) {
        setIsInstalled(true); // App is installed
      } else {
        setIsInstalled(false); // App is not installed
      }
    }, 1000); // 1-second delay
  };

  const [isStartedModal, setIsstartedModal] = useState(false);

  const handleDesktopOpen = () => {
    if (!maintenance.active) {
      const shutDownTimeout = cookies["isShutdownClicked"];
      const currentTime = new Date().getTime();
      const startedAttimeDifference =
        60000 - (currentTime - parseInt(shutDownTimeout || "0"));
      if (startedAttimeDifference > 0) {
        setIsstartedModal(true);
        removeCookie("isStreamConnected", { path: "/" });
        removeCookie("windowClosedAt", { path: "/" });
      } else {
        if (!timeLeft) {
          removeCookie("isShutdownClicked", { path: "/" });
          // removeCookie("windowClosedAt", { path: "/" });
          if ((user && (user.currentPlan === "Basic" || user.currentPlan === "basic")) || !user) {
            setBasicDenied(true)
          } else if (!userInfo.emailVerified) {
            setEmailDenied(true)
          } else if (startDisabled) {
            setOpenStartDisabled(true)
          } else {
            // setComingSoon(true)
            setTimeout(() => {
              stream('desktop')
            }, 500)
          }
        }
      }
    } else {
      setMaintenanceModal(true)
    }
  }


  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const newUser = localStorage.getItem('newAccount');
    const storedTime = localStorage.getItem("timer");
    const currentTime = new Date().getTime();
    if (storedTime && newUser) document.body.style.overflow = 'hidden';
    // localStorage.setItem("timer", currentTime.toString());
    const timeDifference = 90000 - (currentTime - parseInt(storedTime || "0"));
    setTimeLeft(Math.max(0, timeDifference));
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      // localStorage.setItem("timer", currentTime.toString());
      const timeDifference = 90000 - (currentTime - parseInt(storedTime || "0"));
      setTimeLeft(Math.max(0, timeDifference));
      if (timeDifference <= 0) {
        document.body.style.overflow = '';
        clearInterval(intervalId);
        localStorage.removeItem('timer');
        if (newUser && newUser === "true") setTutorialModal(true)
        localStorage.setItem("newAccount", "false");
      }
    }, 1000); // Update timer every second
    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor(timeLeft / 60000);
  const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

  const [isToggledOn, setIsToggledOn] = useState(false);

  const handleToggleButton = () => {
    setIsToggledOn(prevState => !prevState);
  };

  const [cookies, setCookie, removeCookie] = useCookies([
    "shutDownTimeout",
    "isShutdownClicked",
    "isStreamConnected",
    "refreshTimeout",
    "windowClosedAt",
  ]);

  useEffect(() => {
    const isStreamConnected = cookies["isStreamConnected"];
    const refreshTime = cookies["windowClosedAt"];
    if (isStreamConnected && refreshTime) {
      const currentTime = new Date().getTime();
      const startedAttimeDifference =
        55000 - (currentTime - parseInt(refreshTime || "0"));
      if (startedAttimeDifference > 0) {
        setTimeout(() => {
          const currentTime1 = new Date().getTime();
          setCookie("isShutdownClicked", currentTime1.toString(), {
            path: "/",
          });
        }, startedAttimeDifference);
      } else if (
        startedAttimeDifference + 30000 > 0 &&
        startedAttimeDifference <= 0
      ) {
        const currentTime1 = new Date().getTime();
        setCookie(
          "isShutdownClicked",
          (currentTime1 + startedAttimeDifference).toString(),
          {
            path: "/",
          }
        );
      } else {
        removeCookie("isStreamConnected", { path: "/" });
      }
    }
  }, []);

  useEffect(() => {
    const blockedIds = ['65f574b1136fe2f30744ba7e', "6646dff9465ec18bde7549a3", '6645ad2d0f6deed1587dcb93', '6634dd0d9a611aeb3d9b781c', "6644605552c2213b4777dd13", '669a153e8944e959ce1d0e7e', '661f846dfb6c209ae828e4da', '675815f4e3727fa9011d9970', '67065f2bec97840fc182288d', '66e6c156cf6ca3677a6aca09', '672723843adc99366362da44', '6655fd821313f589ef10f65f', '66bcba67a629378d9115159a', '6740215e0853fc1aadc70fff', '668ebb771d4184b9c86b3eca', '66dae4521cbe151f4fcb7c01', '6727cd01b26ff6993aadef0a', '66cb3f7ea370c138eec27847', '66b338656c6400609f197968', '67177f30a894a7a4b8ce8776', '66e7dc6cf1cfb02249c1b40c', '66a51e56f76521775086173b', '66d9557d314c93cd7ec4e6c3', '6706c0618a0975f757d27f0d', '66cc788cbfa5f1f6439e6711'];
    if(user && !blockedIds.includes(user.id)) navigate('/stream');
  },[user])

  return (
    <>

      {/* Maintenance Modal */}
      <Modal
        open={maintenanceModal}
        onClose={handleMaintenanceModalClose}
        className="ComingSoon-modal"
      >
        <Box sx={basicDeniedStyle1}>
          <Typography sx={{ textAlign: "center" }} variant="p">
            {/* {maintenance.message ? maintenance.message : "Our service won't be available due to an emergency maintenance. Thank you for your patience and support."} */}
            {maintenance.message ? maintenance.message : 'The platform will be on maintenance from 12 (Noon) till 5 pm, on 7th October for server addition & hardware upgrade.'}
            {/* Our service won't be available due to an emergency maintenance from 12:30PM to 2PM today (Wednesday). Thank you for your patience and support. */}
            {/* Our sincere apologies for the inconvenience caused. Users will not be able to connect to their PCs. We are currently investigating the issue. Thank you for your patience and support. */}
          </Typography>
        </Box>
      </Modal>


      {/* Tutorial Modal */}
      <Modal onClose={false} open={tutorialModal}>
        <Box sx={tutorialStyle}>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={6000}
            centerMode={false}
            className="Windowsdashboard-carousal"
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <Grid sx={{ alignItems: "center", height: "100%" }} container spacing={2}>
              <Grid className='Windowsdashboard-modal-img-grid' item xs={6}>
                <img
                  id="dash"
                  style={{ width: "100%", height: "100%" }}
                  alt="logo"
                  src={dashboardImg}
                />
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
                <p style={{ width: "80%" }}>
                  Hi! Welcome to AntCloud, Your account is now ready.
                  <br /><br />
                  This is your Dashboard.
                  <br /> <br />
                  From here, you can directly access your own PC & use it however you please.
                </p>
              </Grid>
            </Grid>
            <Grid sx={{ alignItems: "center", height: "100%" }} container spacing={2}>
              <Grid className='dashboard-modal-img-grid' item xs={6}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="logo"
                  src={gameImg}
                />
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
                <p style={{ width: "80%" }}>
                  From the games section, you can simply click on the pre-installed games & start playing.
                </p>
              </Grid>
            </Grid>
            <div style={{ width: "50%", margin: "auto", paddingTop: "2rem", height: "100%" }}>
              {/* <div > */}
              <Cancel onClick={() => setTutorialModal(false)} style={{ position: "absolute", right: "5%", top: "0", cursor: "pointer" }} />
              {/* </div> */}
              <h6>Shortcut Keys</h6>
              <br />
              <ul>
                <li><b style={{ color: "#22B9A6" }}>F10</b> - Toggle FullScreen Mode</li>
                <li><b style={{ color: "#22B9A6" }}>F9</b> - Toggle Stream Setting Menu</li>
              </ul>
              <br />
              <br />
              <p>
                <b>
                  Note: From the F9 section, you can change your video stream settings & shut down your PC as well.
                </b></p>
            </div>
          </Carousel>
        </Box>
      </Modal>

      {/* Coming Soon Modal */}
      <Modal
        open={comingSoon}
        onClose={handleClose}
        className='ComingSoon-modal'
      >
        <Box sx={style}>
          <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src={ComingSoon} />
        </Box>
      </Modal>

      {/* Plan Activating Modal */}
      <Modal
        open={openStartDisabled}
        onClose={handleOpenStartDisabledClose}
        className='ComingSoon-modal'
      >
        <Box sx={basicDeniedStyle}>
          <Typography sx={{ textAlign: "center" }} variant="p">
            Your Plan is being activated right now.
            <br /> <br />
            Please wait for a few minutes and then refresh your page to start playing.
          </Typography>
        </Box>
      </Modal>

      {/* Basic Denied Modal */}

      <Modal
        open={basicDenied}
        onClose={handleBasicDeniedClose}
        className='ComingSoon-modal'
      >
        <Box sx={basicDeniedStyle}>

          {user && user.renewDate
            ?
            <Typography sx={{ textAlign: "center" }} variant="p">
              Oops! It seems like your plan has expired.
              <br /> <br />
              Renew your plan now by visiting our pricing page from below Link.
            </Typography>
            :
            <Typography sx={{ textAlign: "center" }} variant="p">
              Oops! It seems like you're not on a paid plan right now.
              If you have recently purchased or renewed a plan, please wait for five minutes and then refresh the page for your plan to activate.
              <br /> <br />
              If you haven't purchased a plan, Please visit our pricing page from below Link.
            </Typography>
          }

          <br /> <br />
          <Link style={{ display: "block", width: "40%", margin: "auto" }} to="/pricing" className='btn-comm'>Pricing</Link>
        </Box>
      </Modal>

      {/* Email Denied Modal */}
      <Modal
        open={emailDenied}
        onClose={handleEmailDeniedClose}
        className='ComingSoon-modal'
      >
        <Box sx={basicDeniedStyle}>
          <Typography sx={{ textAlign: "center" }} variant="p">
            Oops! It seems like you haven't verified your email yet.
            <br /> <br />
            Please verify your email before starting the stream.
          </Typography>
        </Box>
      </Modal>

      {/* Not Allowed to Restart VM */}
      <Modal
        open={isStartedModal}
        onClose={() => setIsstartedModal(false)}
        className="ComingSoon-modal"
      >
        <Box sx={basicDeniedStyle1}>
          <Typography sx={{ textAlign: "center" }} variant="p">
            Please wait for a minute while your machine is being reset.
          </Typography>
        </Box>
      </Modal>

      <Box sx={{ width: '100%' }}>
        <section className={styles.PCSection}>
          <div className={styles.backgroundContainer} onClick={handleDesktopOpen}>
            <img src={bgImage} alt="Background" style={timeLeft > 0 ? { opacity: 0.3 } : {}} className={styles.backgroundImage} />
            <h1 style={timeLeft <= 0 ? { position: "relative" } : {}} className={styles.PCModeHeading}>Welcome to the AntCloud Windows App.</h1>
            <p style={timeLeft <= 0 ? { position: "relative", fontWeight: "bolder" } : {}} className={styles.PCModeText}> If you have already installed our windows app, Click anywhere to launch your virtual PC.</p>

            {timeLeft > 0 ? (
              <div
                className={styles.glightboxVideo}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: "60%",
                  ["@media (min-width:992px)"]: {
                    width: "40%"
                  },
                  color: "#fff",
                  background: '#212121',
                  opacity: "1",
                  padding: "30px 40px",
                  border: '2px solid #000',
                  boxShadow: 24,
                  cursor: "default"
                }}
              >
                <h5>
                  Thank you for your payment.<br />
                  Your account is being setup right now. <br /> <br />
                  It'll be available in <span style={{ color: "#22B9A6" }}>{`0${minutes}`}:
                    {`${seconds > 9 ? "" : "0"}${seconds}`} minutes </span>
                </h5>
              </div>
            ) : (
              <div style={{ position: "absolute", top: "61%", left: "49.3%", transform: "translate(-50%, -50%)" }}>
                <PowerButton
                  isToggledOn={isToggledOn}
                  onToggleButton={handleToggleButton}
                />
              </div>
            )}
          </div>

        </section>
        <br />

        {/* Profile Section */}

        <div className={profileStyle.profile}>
          <h1 className={profileStyle.profile__header}>Profile </h1>
          <div className={profileStyle.profile__box}>
            <div
              className={`${profileStyle.profile__content} ${menuOpen && profileStyle.menu_open}`}
            >
              <span
                onClick={() => {
                  setMenuOpen(true);
                }}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </span>
              {content[menuItem]}
              {/* {user && user.upcomingPlans ? `<p>Your upcoming ${user.upcomingPlans[0].planName} plan will be activated on ${getDateString(user.renewDate)} </p>` : null} */}
              {user && user.currentPlan.toLowerCase() == "basic" && user.renewDate ? <p>Your Plan has expired! Renew your plan today to enjoy uninterrupted playtime. </p> : <div />}
            </div>

            <div
              className={`${profileStyle.profile__menu} ${menuOpen && profileStyle.menu_open
                }`}
            >
              <span
                onClick={() => {
                  setMenuOpen(false);
                }}
                className={`${!menuOpen && profileStyle.none}`}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
              {Object.keys(content).map((item, idx) => (
                <p

                  className={`${profileStyle.profile__menu_item} ${menuItem === "Account" && menuItem === item ? profileStyle.selected_item_left : menuItem === "Billing" && menuItem === item ? profileStyle.selected_item_right : ""
                    }`}
                  key={idx}
                  onClick={() => setMenuItem(item)}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </Box>

    </>

  );
}