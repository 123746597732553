// export const keys = [
//   ['`', '~'],
//   ['1', '!'],
//   ['2', '@'],
//   ['3', '#'],
//   ['4', '$'],
//   ['5', '%'],
//   ['6', '^'],
//   ['7', '&'],
//   ['8', '*'],
//   ['9', '('],
//   ['0', ')'],
//   ['-', '_'],
//   ['=', '+'],
//   ['q', 'Q'],
//   ['w', 'W'],
//   ['e', 'E'],
//   ['r', 'R'],
//   ['t', 'T'],
//   ['y', 'Y'],
//   ['u', 'U'],
//   ['i', 'I'],
//   ['o', 'O'],
//   ['p', 'P'],
//   ['[', '{'],
//   [']', '}'],
//   ['\\', '|'],
//   ['a', 'A'],
//   ['s', 'S'],
//   ['d', 'D'],
//   ['f', 'F'],
//   ['g', 'G'],
//   ['h', 'H'],
//   ['j', 'J'],
//   ['k', 'K'],
//   ['l', 'L'],
//   [';', ':'],
//   ['\'', '"'],
//   ['backspace', 'backspace'],
//   ['z', 'Z'],
//   ['x', 'X'],
//   ['c', 'C'],
//   ['v', 'V'],
//   ['b', 'B'],
//   ['n', 'N'],
//   ['m', 'M'],
//   [',', '<'],
//   ['.', '>'],
//   ['/', '?'],
//   ['space', 'space'],
//   ['shift', 'shift']
// ];


export const keys = [
  ['Esc', 'Esc'],
  ['`', '~'],
  ['1', '!'],
  ['2', '@'],
  ['3', '#'],
  ['4', '$'],
  ['5', '%'],
  ['6', '^'],
  ['7', '&'],
  ['8', '*'],
  ['9', '('],
  ['0', ')'],
  ['-', '_'],
  ['=', '+'],
  ['q', 'Q'],
  ['w', 'W'],
  ['e', 'E'],
  ['r', 'R'],
  ['t', 'T'],
  ['y', 'Y'],
  ['u', 'U'],
  ['i', 'I'],
  ['o', 'O'],
  ['p', 'P'],
  ['[', '{'],
  [']', '}'],
  ['\\', '|'],
  ['Delete', 'Delete'],
  ['Tab', 'Tab'],
  ['a', 'A'],
  ['s', 'S'],
  ['d', 'D'],
  ['f', 'F'],
  ['g', 'G'],
  ['h', 'H'],
  ['j', 'J'],
  ['k', 'K'],
  ['l', 'L'],
  [';', ':'],
  ['\'', '"'],
  ['Enter', 'Enter'],
  ['shift', 'shift'],
  ['z', 'Z'],
  ['x', 'X'],
  ['c', 'C'],
  ['v', 'V'],
  ['b', 'B'],
  ['n', 'N'],
  ['m', 'M'],
  [',', '<'],
  ['.', '>'],
  ['/', '?'],
  ['Backspace', 'Backspace'],
  ['ctrl', 'ctrl'],
  ['alt', 'alt'],
  ['space', 'space'],
  ['ctrl', 'ctrl'],
  ['alt', 'alt'],
  // ['LMB', 'LMB'],
  // ['RMB', 'RMB'],
  // ['space', 'space'],
  // ['shift', 'shift']
];

export const getKeys = (shift) => {
  let returnKeys = [];
  if (shift) returnKeys = keys.map((key, index) => {
    return key[1];
  });
  else returnKeys = keys.map((key) => {
    return key[0];
  });
  return returnKeys;
};
