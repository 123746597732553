import React from "react";
import { useNavigate } from "react-router-dom";
import blog1 from "../../images/blog1.webp";
import blog2 from "../../images/blog2.webp";
const BlogDashboard = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        borderBottom: "2px solid ",
        paddingBottom: "80px",
        backgroundColor: "#171717",
      }}
    >
      <div
        style={{
          maxWidth: "1200px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "40px",
        }}
        className="row"
      >
        {tempData.map((item) => (
          <div
            onClick={() => navigate(`/blogdetail/${item.id}`)}
            className="col-lg-6 latest-newsH-box"
            data-aos="fade-up"
            data-aos-delay="380"
          >
            <div className="latest-newsH-boxInn" style={{ color: "white" }}>
              <img src={item.img} className="blogH-img" alt="" />
              <div className="latest-newH-content">
                <h5 className="blogH-date">{item.date}</h5>
                <h3
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  className="box-heading"
                >
                  {item.title}
                </h3>
                <p className="blogContent">{item.content}</p>
                <a href="/blogdetail/1" className="btn-text">
                  View More <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogDashboard;
const tempData = [
  {
    id: 1,
    title:
      "Revolutionising Video Editing: The Impact of Cloud Computing, Featuring Ant Cloud",
    date: "July 10, 2023",
    img: blog1,
    content:
      "In the digital era, where content creation reigns supreme, the demands for high-quality video editing are escalating rapidly. From amateur vloggers to professional filmmakers, everyone seeks seamless editing processes that not only save time but also elevate the quality of their creations. In this transformative landscape, cloud computing emerges as a game-changer, reshaping the world of video editing as we know it.",
  },
  {
    id: 2,
    title: "Cloud Gaming vs. Traditional Gaming: Exploring the Pros and Cons",
    date: "July 10, 2023",
    img: blog2,
    content:
      "In recent years, the gaming industry has witnessed a revolutionary shift with the emergence of cloud gaming services like Ant Cloud alongside traditional gaming methods. This transition has sparked debates among gamers worldwide, each advocating for their preferred mode of gaming. In this article, we delve into the pros and cons of both cloud gaming and traditional gaming to help you make an informed decision.",
  },
];
