import React, { useEffect, useState } from "react";
const Maintenance = () => {
  const [maintenance, setMaintenance] = useState({ active: false, partialActive: false, message: null });

  useEffect(() => {
    fetch('https://api.antcloud.co/api/globals/maintenance')
      .then((res) => res.json())
      .then((res) => {
        setMaintenance(res);
      })
  }, [])

  return (
    <>
      {(maintenance.active || maintenance.partialActive) &&
        <p
          id="maintenanceDiv"
          style={{
            backgroundColor: "#22b9a6",
            paddingBottom: "1.5rem",
            paddingTop: "2px",
            // position: "fixed",
            top: "0",
            width: "100%",
            marginBottom: "0",
            textAlign: "center",
            zIndex: "1000",
            fontSize: "18px",
            color: "black",
          }}
        >
          {maintenance.message ? maintenance.message : "Our service won't be available due to an emergency maintenance. Thank you for your patience and support."}
          {/* {maintenance.message ? maintenance.message : "The platform will be on maintenance from 12 (Noon) till 5 pm, on 7th October for server addition & hardware upgrade."} */}
          {/* Users may not be able to connect to their PCs. We are looking into the issue. Our apologies for the inconvenience.  */}
          {/* Our service won't be available due to an emergency maintenance from 12:30PM to 2PM today (Wednesday). Thank you for your patience and support. */}
        </p>
      }
    </>

  );
};

export default Maintenance;
