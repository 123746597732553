import { useSelector, useDispatch } from "react-redux";
import FormInput from "../form-input/FormInput";
import style from "./accountDetails.module.scss";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateUserProfileMutation } from "../../app/services/auth/updateProfileService";
import { logout } from "../../features/auth/authSlice";
import { useLogoutQuery } from "../../app/services/auth/logoutService";
import { useNavigate } from 'react-router';
import { removeCredentials } from "../../features/auth/userSlice";
import { authApi } from "../../app/services/auth/authService";

function AccountDetails() {
  const INITIAL_DATA = {
    email: " ",
    firstName: " ",
    lastName: " ",
    phone: " "
  };
  const [accountInfo, setAccountInfo] = useState(INITIAL_DATA);
  const [isLogoutRequest, setIsLogoutRequest] = useState(true);
  // const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, loggedIn, userToken, userInfo } = useSelector(state => state.auth)
  const user = useSelector(state => state.user.userDetails);

  useEffect(() => {
    if (loggedIn && user) {
      const { firstName, lastName, email, phone, resolution } = user;
      setAccountInfo({
        firstName,
        lastName,
        email,
        phone,
        // resolution
        // address: `${address ? address : ""}`,
        // city: `${city ? city : ""}`,
      });
    }
  }, [user]);

  const resolve = {
    email: { label: "Email", placeholder: "Enter Email", type: "email" },
    phone: { label: "Phone", placeholder: "Phone Number", type: "text" },
    firstName: { label: "First Name", placeholder: "First Name", type: "text" },
    lastName: { label: "Last Name", placeholder: "Last Name", type: "text" },
    // resolution: { label: "Resolution", placeholder: "Resolution", type: "text" },
    // address: {
    //   label: "Address",
    //   placeholder: "Enter Address",
    //   type: "address",
    // },
    // State: { label: "City", placeholder: "Enter City", type: "text" },
    // city: { label: "City", placeholder: "Enter City", type: "text" },
  };

  const handleChange = ({ name, value }) => {
    setAccountInfo({ ...accountInfo, [name]: value });
  };

  const { data, error } = useLogoutQuery('logout', { skip: isLogoutRequest });

  const handleLogout = () => {
    setIsLogoutRequest(false);
  }

  const handleEmailVerification = async () => {
    try {
      const response = await fetch(
        "https://api.antcloud.co/api/users/email/otp",
        {
          method: "POST",
          headers: {
            Authorization: `JWT ${userToken}`,
          },
        }
      );
      // if (!response.ok) {
      //   throw new Error("Failed to verify email");
      // }
      const data = await response.json();
      if(data.message !== "")
      toast.success(data.message);
    } catch (error) {
      toast.error("Error while sending email:");
    }
  };

  useEffect(() => {
    if(data && data.message === "You have been logged out succesfully!") {
      dispatch(logout());
      dispatch(removeCredentials());
      navigate('/signin');
      toast.success("Come Back Soon!")
    } else if(error) {
      toast.error('Error Logging Out!')
    }
  },[data, error])

  // const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();

  // const updateProfile = async () => {
  //   var isError = false;
  //   const updatePromise = new Promise(async (resolve, reject) => {
  //     try {
  //       if (!isError) {
  //         console.log(accountInfo);
  //         // await firebase.updateProfile(accountInfo);
  //         // updateUserProfile(accountInfo);
  //         console.log(userData)
  //         setAccountInfo(INITIAL_DATA);
  //         resolve("Succesfully Updated profile");
  //       } else {
  //         const { firstName, lastName, email, phone } = user;
  //         setAccountInfo({
  //           firstName,
  //           lastName,
  //           email,
  //           phone
  //         });
  //       }
  //     } catch (error) {
  //       setError(true);
  //       console.log(error);
  //       reject(error.message);
  //     }
  //   });

  //   toast.promise(updatePromise, {
  //     pending: {
  //       render() {
  //         return "Updating Your Profile";
  //       },
  //     },
  //     success: {
  //       render({ data }) {
  //         return data;
  //       },
  //     },
  //     error: {
  //       render({ data }) {
  //         return data;
  //       },
  //     },
  //   });
  // };

  // const resetPassword = () => {
  //   let errorMsg = "";
  //   firebase
  //     .auth()
  //     .sendPasswordResetEmail(profile.email)
  //     .then(() => {
  //       this.setState({ password: false });
  //     })
  //     .catch((error) => {
  //       if (error.code === "auth/invalid-email") {
  //         errorMsg =
  //           "The email address entered is incorrect. Please check again.";
  //       } else if (error.code === "auth/user-not-found") {
  //         errorMsg =
  //           "User with the given email does not exist. Please check again.";
  //       } else {
  //         errorMsg = "Something went wrong";
  //       }
  //     });
  //   if (errorMsg) {
  //     toast.error(errorMsg);
  //   } else toast.success("Password reset email sent");
  // };

  if (loading) {
    return <div className="loaderforDatabase" />;
  }

  return (
    <div className={style.account}>
      {/* <h3>Update Personal Info.</h3> */}
     
      <h3 className={style.account__heading}>Personal Info.</h3>
      <div className={style.account__form}>
        {Object.keys(accountInfo).map((key, idx) => {
          return (
            <FormInput
              key={idx}
              name={key}
              label={resolve[key].label}
              type={resolve[key].type}
              // disabled={key === "email" || key === "phone"}
              disabled={true}
              placeholder={resolve[key].placeholder}
              value={accountInfo[key]}
              valueChange={handleChange}
            />
          );
        })}
        {/* <div style={{ width: "45%" }}>
          <button
            className={`${style.account_save_button} ${style.account_reset}`}
          // onClick={resetPassword}
          >
            Reset Password
          </button>
        </div> */}
      </div>
      {/* <div style={{ display: "flex", marginTop: "1.5rem", justifyContent: "space-between" }}>
          <button
            onClick={handleLogout}
            className={`${style.account_save_button} ${style.account_reset}`}
          >
            Logout
          </button>
        {!userInfo.emailVerified &&
          <button
            onClick={handleEmailVerification}
            className={`${style.account_save_button} ${style.account_reset}`}
          >
            Resend Verification Mail
          </button>
        }
      </div> */}
     
      {/* <div style={{ marginTop: "1.5rem" }}>
          <button
            className={`${style.account_save_button} ${style.account_reset}`}
          // onClick={resetPassword}
          >
            Reset Password
          </button>
        </div> */}
      {/* <div className={style.account_save_div}>
        <button
          className={style.account_save_button}
          onClick={updateProfile}
        >
          Save
        </button>
      </div> */}
    
    </div>
  );
}

export default AccountDetails;