import React, { useEffect, useState, useCallback, useRef } from "react"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Spinner from "../Components/stream/overlays/spinner";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import fullHDImg from '../images/full-hd.webp';
import allDevicesImg from '../images/all-devices.webp';
import fourkImg from '../images/4k.webp';
import useRazorpay from "react-razorpay";
import AntLogo from '../images/Ant-Logo.webp';
import HdIcon from '@mui/icons-material/Hd';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function MobilePricing() {
    const [Razorpay] = useRazorpay();

    const plans = {
        "Premium": {
            "planName": "Premium (Advanced)",
            "planTerm": 30,
            "planPrice": "599",
            "planHourLimit": 25,
            "resolution": "1080p",
        },
        "Ultimate": {
            "planName": "Ultimate (Advanced)",
            "planTerm": 30,
            "planPrice": "899",
            "planHourLimit": 50,
            "resolution": "4K",
        },
        "UltimateBundle": {
            "planName": "Mystery Bundle",
            "planTerm": 90,
            "planPrice": "2899",
            "planHourLimit": 150,
            "resolution": "4K",
        },
        "GTXPremium": {
            "planName": "Premium (Intro)",
            "planTerm": 30,
            "planPrice": "199",
            "planHourLimit": 35,
            "resolution": "720p",
        },
        "GTXUltimate": {
            "planName": "Ultimate (Intro)",
            "planTerm": 30,
            "planPrice": "499",
            "planHourLimit": 100,
            "resolution": "1080p",
        },
        "TopUp": {
            "planName": "TopUp",
            "planPrice": "40",
            "planHourLimit": 1,
        }
    }

    // Payment Link States
    const [btnStatus, setBtnStatus] = useState(false);
    const [planName, setPlanName] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [idToken, setIdToken] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [goBack, setGoBack] = useState(false);
    const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [notes, setNotes] = useState(null);
    const [appliedCouponCode, setAppliedCouponCode] = useState(null)
    const [couponLoading, setCouponLoading] = useState(false);
    const [discountDetails, setDiscountDetails] = useState(null);
    const [isBundlePlan, setIsBundlePlan] = useState(false);
    const couponRef = useRef();
    couponRef.current = appliedCouponCode;

    // Payment Callback Status
    const [paymentCallbackLoading, setPaymentCallbackLoading] = useState(false);

    const notesRef = useRef();
    notesRef.current = notes;

    const subscriptionIdRef = useRef();
    subscriptionIdRef.current = order;

    const webViewAvailableRef = useRef();
    webViewAvailableRef.current = "bleh";

    // const createPaymentLink = (planNameRef) => {
    //     const planName = planNameRef.textContent;
    //     if (loggedIn) {
    //         setBtnStatus(false);
    //         setPaymentLinkLoading(true)
    //         fetch("https://api.antcloud.co/api/subscriptions/create", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: "JWT " + userToken,
    //             },
    //             body: JSON.stringify({
    //                 planName: planName,
    //                 platform: "Browser",
    //                 couponCode: appliedCouponCode
    //             }),
    //         })
    //             .then((response) => response.json())
    //             .then((response) => {
    //                 if (response && response.message && !response.paymentLink) {
    //                     toast.error(response.message);
    //                     setSelectedCard(null)
    //                     // setPlanError(response.message)
    //                 } else {
    //                     setBtnStatus(true);
    //                     setSubscriptionId(response.subscriptionId)
    //                     setNotes(response.notes)
    //                     // setPaymentLink(response.paymentLink);
    //                 }
    //                 setPaymentLinkLoading(false)
    //             })
    //             .catch((err) => {
    //                 setPaymentLinkLoading(false)
    //                 toast.error('Something went wrong!')
    //                 console.log(err)
    //             });
    //     } else {
    //         setBtnStatus(true);
    //         // setPaymentLink("/signin");
    //         console.log("API token not found");
    //     }
    // };

    // Modal
    // const [comingSoon, setComingSoon] = useState(false);
    // const handleClose = () => {
    //     setBtnStatus(false);
    //     setComingSoon(false);
    //     setWaitList(false);
    //     setSelectedCard(null);
    // };

    // const style = {
    //     position: 'absolute',
    //     textAlign: 'center',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: "90%",
    //     ["@media (min-width:992px)"]: {
    //         width: "40%",
    //     },
    //     // width: 600,
    //     color: "#fff",
    //     bgcolor: '#212121',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    // };

    const createPaymentLink = (planName, idToken, quantity, isBundle) => {
        // let coupon = couponRef.current;
        // if(planName.toLowerCase().includes('gtx')) coupon = null;
        if (idToken) {
            setBtnStatus(false)
            setPaymentLinkLoading(true)
            fetch("https://api.antcloud.co/api/order/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + idToken,
                },
                body: JSON.stringify({
                    planName: planName,
                    platform: "android",
                    quantity: quantity,
                    couponCode: couponRef.current,
                    bundle: isBundle == 'true' ? true : false
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response && response.message) {
                        if (response.message.includes("Token Expired")) {
                            toast.error("Token Expired")
                        } else if (response.message.includes("Invalid Token")) {
                            toast.error("Session Expired! Please login again.")
                        } else {
                            toast.error(response.message);
                        }
                        // setPlanError(response.message)
                    } else {
                        setBtnStatus(true);
                        setOrder(response)
                        setNotes(response.notes)
                        // setPaymentLink(response.paymentLink);
                    }
                    setPaymentLinkLoading(false)
                })
                .catch((err) => {
                    setPaymentLinkLoading(false)
                    toast.error('Something went wrong!')
                    console.log(err)
                });
        } else {
            setBtnStatus(true);
            // setPaymentLink("/signin");
            console.log("API token not found");
        }
    };

    const loaderModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        '&:focus-visible': {
            outline: "none"
        }
    };

    // const checkTransaction = (response) => {
    //     setPaymentCallbackLoading(true)
    //     setTimeout(() => {
    //         setPaymentCallbackLoading(false)
    //         toast.success("Payment Done!")
    //         setGoBack(true)
    //         if (window.Android) window.Android.showMessageInNative('successful');
    //     }, 10000)
    //     // fetch("https://api.antcloud.co/api/subscriptions/verifyCallback",
    //     //     {
    //     //         method: 'POST',
    //     //         headers: {
    //     //             'Content-Type': "application/json"
    //     //         },
    //     //         body: JSON.stringify({
    //     //             payId: response.razorpay_payment_id,
    //     //             subscriptionId: subscriptionIdRef.current,
    //     //             sign: response.razorpay_signature
    //     //         })
    //     //     }
    //     // )
    //     //     .then(result => result.json())
    //     //     .then((result) => {
    //     //         setTimeout(() => {
    //     //             setPaymentCallbackLoading(false)
    //     //             toast.success("Payment Done!")
    //     //             if (window.Android) window.Android.showMessageInNative('ended');
    //     //         }, 5000)
    //     //     }).catch(err => {
    //     //         setPaymentCallbackLoading(false)
    //     //         toast.error("Payment Failed!")
    //     //     })
    // };


    const checkTransaction = (response) => {
        setPaymentCallbackLoading(true)
        fetch("https://api.antcloud.co/api/subscriptions/verifyCallback",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    payId: response.razorpay_payment_id,
                    orderId: subscriptionIdRef.current.id,
                    sign: response.razorpay_signature
                })
            }
        )
            .then(result => result.json())
            .then((result) => {
                if (result.result !== "Failed") {
                    if (notesRef.current.planName.toLowerCase() === "topup") {
                        setPaymentCallbackLoading(false)
                        toast.success("Payment Done!", {
                            autoClose: false
                        })
                        setGoBack(true)
                        // navigate('/stream')
                        if (window.Android) window.Android.showMessageInNative('complete');
                        else console.log('Android interface not available')
                    } else {
                        setTimeout(() => {
                            setPaymentCallbackLoading(false)
                            toast.success("Payment Done!", {
                                autoClose: false
                            })
                            setGoBack(true)
                            // navigate('/stream')
                            if (!notesRef.current.addToUpcoming && !notesRef.current.upgrade && !notesRef.current.renew) {
                                if (window.Android) window.Android.showMessageInNative('success');
                                else console.log('Android interface not available')
                                // localStorage.setItem('newAccount', "true");
                                // const currentTime = new Date().getTime();
                                // localStorage.setItem("timer", currentTime.toString());
                            } else {
                                if (window.Android) window.Android.showMessageInNative('complete');
                                else console.log('Android interface not available')
                            }
                        }, 5000)
                    }
                } else {
                    setPaymentCallbackLoading(false)
                    toast.error("Payment Failed!", {
                        autoClose: false
                    })
                    if (window.Android) window.Android.showMessageInNative('failed');
                    else console.log('Android interface not available')
                }
            }).catch(err => {
                setPaymentCallbackLoading(false)
                toast.error("Payment Failed!", {
                    autoClose: false
                })
                if (window.Android) window.Android.showMessageInNative('failed');
                else console.log('Android interface not available')
            })
    };

    const handleValidateCouponCode = (couponCode) => {
        setCouponLoading(true)
        fetch(`https://api.antcloud.co/api/coupons/check?code=${couponCode}`)
            .then(async (res) => {
                const response = await res.json();
                if (response.active) {
                    setDiscountDetails({ type: response.type, amount: response.discountAmount })
                }
                setCouponLoading(false)
            }).catch((err) => {
                console.log(err)
                setCouponLoading(false)
            })
    }


    const handlePayment = useCallback(async () => {
        const options = {
            key: process.env.RAZORPAY_KEY_ID,
            order_id: subscriptionIdRef.current.id,
            amount: subscriptionIdRef.current.amount,
            currency: "INR",
            name: "Antcloud",
            description: `Subscribe for AntCloud Plan`,
            image: "https://antcloud.co/static/media/Ant-Logo.8bee7434f594d1115fc3.webp",
            handler: (res) => checkTransaction(res),
            prefill: {
                name: notesRef.current.fullName,
                email: notesRef.current.email,
                contact: notesRef.current.phone,
            },
            notes: notesRef.current,
            theme: {
                color: "#22B9A6",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.on("payment.failed", function (response) {
            toast.error("Payment Failed!", {
                autoClose: false
            })
            if (window.Android) window.Android.showMessageInNative('failed');
            else console.log('Android interface not available')
        });
        rzpay.open();
    }, [Razorpay]);

    const handleGoBack = () => {
        if (window.Android) {
            window.Android.showMessageInNative('ended');
        }
    }

    // useEffect(() => {
    //     // Example of waiting for window.Android to be available
    //     if (window.Android) {
    //         console.log('Android interface available hai');
    //         webViewAvailableRef.current = true;
    //         setWebViewCheck(true)
    //     } else {
    //         // webViewAvailableRef.current = false;
    //         // setWebViewCheck(false)
    //         console.log('Android interface not available yet');
    //     }
    // }, []);

    // function handleClick () {
    //     console.log("STATUS")
    //     console.log(webViewCheck)
    //     console.log(webViewAvailableRef.current)
    //     if (webViewCheck || webViewAvailableRef.current) {
    //         handleGoBack(); // Call handleGoBack only if window.Android is available
    //     } else {
    //         console.log('Android interface not available yet. Waiting...');
    //         // Optionally, set a timeout to retry or provide user feedback
    //     }
    // }

    useEffect(() => {
        if (window.location.pathname === "/mobilePricing") {
            const queryParams = new URLSearchParams(window.location.search);
            const userSubscriptionId = queryParams.get('subscriptionId');
            let selectedPlan = queryParams.get('planName');
            const isBundle = queryParams.get('bundle');
            setIsBundlePlan(isBundle);
            if(isBundle == 'true') {
                selectedPlan = 'UltimateBundle'
            }
            const couponCode = queryParams.get('couponCode');
            couponRef.current = queryParams.get('couponCode')
            setAppliedCouponCode(couponCode)
            if(couponCode) handleValidateCouponCode(couponCode);
            const planQuantity = selectedPlan.toLowerCase() === "topup" ? queryParams.get('quantity') : 1;
            const idTokenRef = queryParams.get('idToken');
            if (planQuantity) setQuantity(planQuantity);
            setSubscriptionId(userSubscriptionId);
            setPlanName(selectedPlan);
            setIdToken(idTokenRef)
           
            if (idTokenRef) {
                createPaymentLink(selectedPlan, idTokenRef, planQuantity, isBundle)
            }
        }
    }, [])

    return (
        <>
            {/* {!goBack ?  */}
            <div style={{ minHeight: "100vh", backgroundColor: "#111" }}>
                <div style={{ textAlign: "center" }}>
                    <img src={AntLogo} style={{ display: "block", margin: "auto", maxWidth: "40%", height: "auto" }} />
                </div>
                <Modal
                    open={paymentCallbackLoading}
                    // onClose={handleClose}
                    className='ComingSoon-modal'
                >
                    <Box sx={loaderModalStyle}>
                        <Spinner />
                    </Box>
                </Modal>
                <div
                    style={{ margin: "auto", width: "70%", height: "55%" }}
                    className={`pricingN-cols plans selectedPrice`}
                >
                    <ul>
                        <li className="pricingN-heading">
                            <div className={`radio-custom active`}>
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                <span style={{ fontSize: "1rem" }} className="pricing-tableHeading">{planName && plans[planName].planName}</span>
                            </div>
                        </li>
                        {/* <li className="pricingN-subHeading"><i className="fas fa-rupee-sign"></i> &nbsp;{planName && planName == "TopUp" ? (parseInt(plans[planName].planPrice * quantity)) : planName && plans[planName].planPrice}</li> */}
                        <li className="pricingN-subHeading">
                            <i className="fas fa-rupee-sign"></i>
                            &nbsp;
                            {planName && planName === "TopUp"
                                ? plans[planName].planPrice * quantity
                                : discountDetails ? <span className="bold-strikethrough">{plans[planName].planPrice}</span> : planName && plans[planName].planPrice
                            }
                            &nbsp;
                            {discountDetails && discountDetails.type === "percentage"
                                ? planName && planName !== "TopUp" ? plans[planName].planPrice - (plans[planName].planPrice * (discountDetails.amount / 100)) : null
                                : discountDetails && discountDetails.type === "fixed"
                                    ? planName && planName !== "TopUp" ? plans[planName].planPrice - discountDetails.amount : null
                                    : null
                            }
                        </li>
                        <li>
                            <p>
                                <i className="fas fa-hourglass-start"></i>
                                {planName && planName === "TopUp" ? (parseInt(plans[planName].planHourLimit) * quantity) : planName && plans[planName].planHourLimit} Hours
                            </p>
                        </li>
                        <li>
                            <p>
                                {planName && plans[planName].resolution === "720p" ? <HdIcon fontSize="large" /> :
                                    <img
                                        src={planName && plans[planName].resolution === "1080p" ? fullHDImg : fourkImg}
                                        alt={planName && plans[planName].resolution}
                                    />
                                }
                                {planName && plans[planName].resolution ? `Upto ${planName && plans[planName].resolution}` : 'Depending on Base plan'}
                            </p>
                        </li>
                        <li>
                            <p>
                                <img src={allDevicesImg} alt="RAM/VRAM" />
                                {planName && (planName === "GTXPremium" || planName === "GTXUltimate") ? "18GB RAM/6GB VRAM" : planName === "TopUp" ? "Depending on Base plan" : "20GB RAM/16GB VRAM"}
                            </p>
                        </li>
                        <li>
                            <p>
                                {
                                    planName && (planName === "GTXPremium" || planName === "GTXUltimate")
                                        ?
                                        <CloseIcon fontSize="large" />
                                        :
                                        <CheckIcon fontSize="large" />

                                }
                                {planName === "GTXPremium" || planName === "GTXUltimate" ? "RTX Disabled" : planName === "TopUp" ? "Depending on Base plan" : "RTX Enabled"}
                            </p>
                        </li>
                        <li>
                            <p>
                                <i className="fas fa-calendar-alt"></i>
                                {planName && plans[planName].planTerm ? planName && plans[planName].planTerm + 'Days' : 'Depending on Base plan'}
                            </p>
                        </li>
                    </ul>
                </div>
                <div style={{ display: "flex", justifyContent: "center", paddingBottom: "3rem" }}>
                    {
                        goBack
                            ? <button className="btn-comm btn-lg-2 btnNext mobile-btn-cta" style={{ marginTop: "3rem" }} onClick={handleGoBack}>Go Back</button>
                            : <button className="btn-comm btn-lg-2 btnNext mobile-btn-cta" style={paymentLinkLoading ? { position: "relative", height: "3rem", marginTop: "2rem" } : { marginTop: "2rem" }} onClick={handlePayment}>{paymentLinkLoading ? <> <Spinner bottomPosition={1} /> </> : <>Buy Plan</>}</button>
                    }
                </div>
            </div>
            {/* :
                 <button className="btn-comm btn-lg-2 btnNext" style={{ marginTop: "3rem" }} onClick={handleGoBack}>Go Back</button> */}
            {/* } */}
        </>
    )
}