import React, { useEffect, useState } from "react";

const GamepadButtons = ({
  gamepadIndex,
  buttonsPressed,
  setButtonsPressed,
}) => {
  const [leftJoystickY, setLeftJoystickY] = useState({
    RJY: "",
    LJX: "",
    RJX: "",
    LJY: "",
  });

  useEffect(() => {
    let animationFrameId;
    const updateGamepadStatus = () => {
      const gamepad = navigator.getGamepads()[gamepadIndex];
      if (gamepad) {
        const pressedButtons = gamepad.buttons
          .map((button, index) => (button.pressed ? index : null))
          .filter((index) => index !== null);
        // pressedButtons &&
        //   pressedButtons.length > 0 &&
        setButtonsPressed(pressedButtons[0]);
        // console.log(pressedButtons[0]);
        if (
          Number(gamepad.axes[0]).toFixed(4) > 0.5 ||
          Number(gamepad.axes[0]).toFixed(4) < -0.5
        ) {
          setButtonsPressed(16);
        } else if (
          Number(gamepad.axes[1]).toFixed(4) > 0.5 ||
          Number(gamepad.axes[1]).toFixed(4) < -0.5
        ) {
          setButtonsPressed(17);
        } else if (
          Number(gamepad.axes[2]).toFixed(4) > 0.5 ||
          Number(gamepad.axes[2]).toFixed(4) < -0.5
        ) {
          setButtonsPressed(18);
        } else if (
          Number(gamepad.axes[3]).toFixed(4) > 0.5 ||
          Number(gamepad.axes[3]).toFixed(4) < -0.5
        ) {
          setButtonsPressed(19);
        }
        setLeftJoystickY({
          LJX: gamepad.axes[0],
          LJY: gamepad.axes[1],
          RJX: gamepad.axes[2],
          RJY: gamepad.axes[3],
        });
      }
      animationFrameId = requestAnimationFrame(updateGamepadStatus);
    };
    updateGamepadStatus();
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [gamepadIndex]);

  return (
    <div>
      {/* <h2>Buttons Pressed:</h2>
      <ul>
        {buttonsPressed ? (
          // buttonsPressed.map((index) => (
          <li key={0}>Button {buttonsPressed} pressed</li>
        ) : (
          // ))
          <li>No buttons pressed</li>
        )}
      </ul>
      <h2>Left Joystick Y-Axis:</h2>
      <p>
        {Number(leftJoystickY.RJX).toFixed(4)} and{" "}
        {Number(leftJoystickY.RJY).toFixed(4)}
        <br />
        {Number(leftJoystickY.LJX).toFixed(4)} and{" "}
        {Number(leftJoystickY.LJY).toFixed(4)}
      </p> */}
    </div>
  );
};

export default GamepadButtons;
