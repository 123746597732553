import React from "react"
import { Helmet } from "react-helmet";

export default function HelmetProvider() {
    return (
        <Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href="https://antcloud.co/favicon.ico" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#22B9A6" />
            <meta name="title" content="Ant Cloud - India's First Cloud Gaming and PC Platform." />
            <meta name="description" content="Turn your old devices into Cloud Gaming Beasts! Play any AAA Game now on any device you own in India, Without the need to buy additional hardware." />
        </Helmet>
    )
}