import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../AuthContext"
import { useDispatch, useSelector } from "react-redux";
import { useRefreshUserTokenQuery } from "../app/services/auth/refreshService";
import { setCredentials } from "../features/auth/userSlice";
import { refreshTokens } from "../features/auth/authSlice";
import { toast } from "react-toastify";

export default function Footer() {
    // const routes = ['/']
    const { isStreamStarted } = useAuth();
    const { loggedIn } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const data = useRefreshUserTokenQuery();
    // const { data, error } = useRefreshUserTokenQuery( 
    // // {
    // //     pollingInterval: 15000
    // // }
    // )

    // useEffect(() => {
    //     if(data) {
    //         dispatch(refreshTokens(data))
    //     }
    //     if(error && (error.data.includes("Re-authentication needed!") || error.data.includes("Refresh Token Expired") || error.data.includes("Forbidden Request!"))) {
    //         navigate('/signin')
    //         toast.error('Session Explired! Please Sign in again.')
    //     }
    // },[data, dispatch])

    return (
        // <footer className="footer-sec">
        //     <div className="container custom-container">
        //         <div className="footer-content" >
        //             <p>© AntCloud. All Rights Reserved 2023.</p>
        //             <ul className="footer-social-links">
        //                 <li>
        //                     <a href="#">Facebook</a>
        //                 </li>
        //                 <li>
        //                     <a href="#">Youtube</a>
        //                 </li>
        //                 <li>
        //                     <a href="#">Instagram</a>
        //                 </li>
        //                 <li>
        //                     <a href="#">LinkedIn</a>
        //                 </li>
        //                 <li>
        //                     <a href="#">Discord</a>
        //                 </li>

        //             </ul>
        //         </div>
        //     </div>
        // </footer>
        <footer style={isStreamStarted ? { display: "none" } : { display: "block" }} className="footer-sec">
            <div className="footerStyling">
                <div className="footerRow">
                    <div className="footerColumn">Pages
                        <ul className="FooterList">
                            <li><Link style={{ color: "#22B9A6" }} to='/'>Home</Link></li>
                            <li><Link style={{ color: "#22B9A6" }} to='/pricing'>Pricing</Link></li>
                            <li><Link style={{ color: "#22B9A6" }} to='/download'>Downloads</Link></li>
                            {loggedIn && <li><a style={{ color: "#22B9A6" }} href='/controllerMapping'>Controller Mapping</a></li>}
                        </ul>
                    </div>

                    <div className="footerColumn">Help
                        <ul className="FooterList">
                            <li><Link style={{ color: "#22B9A6" }} to='/faq'>FAQs</Link></li>
                            <li><Link style={{ color: "#22B9A6" }} to='/support'>Support</Link></li>

                        </ul>
                    </div>

                    <div className="footerColumn">Legal
                        <ul className="FooterList">
                            <li><Link style={{ color: "#22B9A6" }} to='/tnc'>Terms & Conditions</Link></li>
                            <li><Link style={{ color: "#22B9A6" }} to='/privacyPolicy'>Privacy Policy</Link></li>
                            <li><Link style={{ color: "#22B9A6" }} to='/cancellationPolicy'>Cancellation Policy</Link></li>
                            <li><Link style={{ color: "#22B9A6" }} to='/shippingPolicy'>Shipping Policy</Link></li>
                            <li><Link style={{ color: "#22B9A6" }} to='/disclaimer'>Disclaimer</Link></li>
                        </ul>
                    </div>
                    <div className="footerColumn">Socials
                        <ul className="FooterList">
                            <li>
                                <a style={{ color: "#22B9A6" }} target="_blank" rel="noreferrer noopener" href='https://www.facebook.com/AntPlay.tech/'>
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a style={{ color: "#22B9A6" }} target="_blank" rel="noreferrer noopener" href='https://www.youtube.com/channel/UCxcqbWaOReR9O9PlTneYqAQ'>
                                    Youtube
                                    {/* <a className="SocialLinks" href="#">Youtube</a> */}
                                </a>
                            </li>
                            <li>
                                <a style={{ color: "#22B9A6" }} target="_blank" rel="noreferrer noopener" href='https://www.instagram.com/antcloudco'>
                                    Instagram
                                    {/* <a className="SocialLinks" href="#">Instagram</a> */}
                                </a>
                            </li>
                            <li>
                                <a style={{ color: "#22B9A6" }} target="_blank" rel="noreferrer noopener" href='https://in.linkedin.com/company/antcloud'>
                                    LinkedIn
                                    {/* <a className="SocialLinks" href="#">LinkedIn</a> */}
                                </a>
                            </li>
                            <li>
                                <a style={{ color: "#22B9A6" }} target="_blank" rel="noreferrer noopener" href='https://discord.gg/fZRwkKwZQB'>
                                    Discord
                                    {/* <a className="SocialLinks" href="#">Discord</a> */}
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
                <p style={{ alignContent: "center", textAlign: "center", marginTop: "1rem" }}>© AntCloud. All Rights Reserved 2024.</p>
            </div>
        </footer>
    )
}