import React, { useState } from "react";
import audio from '../images/enter.mp3';

export function PowerButton({ isToggledOn, onToggleButton }) {
    const audioRef = React.createRef();
    const [checkClick, setCheckClick] = useState(false);
    const onMouseUp = () => {
        setTimeout(() => {
            setCheckClick(false)
        },100)
    }
    return (
    <div>
      <button className={`power_button ${checkClick ? 'scale' : ''} ${isToggledOn ? 'is-active' : ''}`} onMouseUp={onMouseUp} onClick={() => {
        setCheckClick(true)
        onToggleButton();
        audioRef.current.play();
      }}>
        <div className="power_button__icon">
          <span className="power_button__icon__arrow"></span>
        </div>
      </button>
      <audio ref={audioRef} src={audio} />
    </div>
  );
}