import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import styles from './dashboard.module.css';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import bgImage from '../../images/PCMode.webp'
import profileStyle from "./profile.module.scss";
import AccountDetails from "../account-details/AccountDetails";
import BillingDetails from "../billing-details/BillingDetails";
import Miscellaneous from "../game-settings/Miscellaneous";
import ComingSoon from '../../images/ComingSoon.webp';
import dashboardImg from '../../images/DashboardImg.webp';
import gameImg from '../../images/OurGames.webp';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Cancel, Close } from '@mui/icons-material';
import Settings from '../stream/overlays/settingsModal';
import { zoom } from '../zoom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { PowerButton } from '../PowerButton';
import { useCookies } from 'react-cookie';

export function Dashboard({ maintenance, games, stream }) {

  const navigate = useNavigate();
  const gamesRef = useRef(null);
  gamesRef.current = gamesRef;
  const dispatch = useDispatch();
  const { loggedIn, userInfo } = useSelector((state) => state.auth);
  const user = useSelector(state => state.user.userDetails);

  // Modal
  const [comingSoon, setComingSoon] = useState(false);
  const handleOpen = () => setComingSoon(true);
  const handleClose = () => setComingSoon(false);

  const [basicDenied, setBasicDenied] = useState(false);
  const handleBasicDeniedClose = () => setBasicDenied(false);

  const [maintenanceModal, setMaintenanceModal] = useState(false);
  const handleMaintenanceModalClose = () => setMaintenanceModal(false);

  const [emailDenied, setEmailDenied] = useState(false);
  const handleEmailDeniedClose = () => setEmailDenied(false);

  const [startDisabled, setStartDisabled] = useState(false);
  const [openStartDisabled, setOpenStartDisabled] = useState(false);
  const [tutorialModal, setTutorialModal] = useState(false);
  const location = useLocation();
  const handleOpenStartDisabledClose = () => setOpenStartDisabled(false);

  const [playButton, setPlayButton] = useState(true);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    '&:focus-visible': {
      outline: "none"
    }
  };

  const basicDeniedStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    ["@media (min-width:992px)"]: {
      width: "40%"
    },
    // width: 600,
    color: "#fff",
    bgcolor: '#212121',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const basicDeniedStyle1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    ":focus-visible": {
      "outline": "none"
    },
    ["@media (min-width:992px)"]: {
      width: "40%"
    },
    // width: 600,
    color: "#fff",
    bgcolor: '#212121',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center"
  };

  const tutorialStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    height: "50%",
    // width: 600,
    color: "#fff",
    bgcolor: '#212121',
    border: '2px solid #000',
    '&:focus-visible': {
      outline: "none"
    },
    boxShadow: 24,
    p: 4,
  };

  // Profile
  const [menuItem, setMenuItem] = useState("Account");
  const [menuOpen, setMenuOpen] = useState(false);

  const content = {
    Account: <AccountDetails />,
    Billing: <BillingDetails />,
    // Settings: <Miscellaneous />,
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [menuItem]);

  // useEffect(() => {
  //   console.log(user)
  //   if(user && user.message === "Token Expired")
  // })

  // useEffect(() => {
  //     const search = window.location.search;
  //     const params = new URLSearchParams(search);
  //     const paramIdToken = params.get('idToken');
  //     console.log(user)
  //     if (user && user.message && user.message === "Invalid token") {
  //         console.log("LS");
  //         // deleteCookie('antplay-tech')
  //         // setIsLoggedIn(false);
  //         // navigate('/signin')
  //     } else if (user && user.token) {
  //         if (user.renewDate !== null) {
  //             let now = Date.now();
  //             let renewDate = Date.parse(user.renewDate);
  //             console.log(user.renewDate)
  //             const daysLeftInPlan = Math.floor((renewDate - now) / 86400000);
  //             if (daysLeftInPlan > 0) setDaysLeft(daysLeftInPlan)
  //             else if (daysLeftInPlan === 0) setDaysLeft(0)
  //             else setDaysLeft(-1)
  //         }
  //         setAvatar(user.firstName.slice(0, 1).toUpperCase() + user.lastName.slice(0, 1).toUpperCase());
  //     }
  // }, [user])

  // const handleLogout = () => {
  //     setIsLoggedIn(false);
  //     deleteCookie("antplay-tech");
  //     navigate('/signin', { replace: true });
  // }

  // const calculateTimeRemaining = () => {
  //     if (user.totalTimeMonth !== 0) {
  //         const minutes = user.totalTimeMonth - user.timeUsedMonth;
  //         const hours = Math.floor(minutes / 60); // Get the whole number of hours
  //         const remainingMinutes = minutes % 60; // Get the remaining minutes after converting to hours
  //         return hours + " hours " + remainingMinutes + " minutes";
  //     } else return 'N/A'
  // }

  // const calculateDaysLeft = (dateString) => {
  //     if (dateString !== null) {
  //         // Parse the given date string into a Date object
  //         var targetDate = new Date(dateString);

  //         // Get the current date
  //         var currentDate = new Date();

  //         // Calculate the difference in milliseconds between the target date and the current date
  //         var differenceMs = targetDate - currentDate;

  //         // Convert milliseconds to days
  //         var daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
  //         console.log(daysLeft)
  //         return daysLeft;
  //     } else return 'N/A'
  // }

  const [isStartedModal, setIsstartedModal] = useState(false);

  const handleDesktopOpen = () => {
    if(!maintenance.active) {
      const shutDownTimeout = cookies["isShutdownClicked"];
      const currentTime = new Date().getTime();
      const startedAttimeDifference =
        60000 - (currentTime - parseInt(shutDownTimeout || "0"));
      if (startedAttimeDifference > 0) {
        setIsstartedModal(true);
        removeCookie("isStreamConnected", { path: "/" });
        removeCookie("windowClosedAt", { path: "/" });
      } else {
        if (!timeLeft) {
          removeCookie("isShutdownClicked", { path: "/" });
          // removeCookie("windowClosedAt", { path: "/" });
          if ((user && (user.currentPlan === "Basic" || user.currentPlan === "basic")) || !user) {
            setBasicDenied(true)
          } else if (!userInfo.emailVerified) {
            setEmailDenied(true)
          } else if (startDisabled) {
            setOpenStartDisabled(true)
          } else {
            // setComingSoon(true)
            setTimeout(() => {
              stream('desktop')
            }, 500)
          }
        }
      }
    } else {
      setMaintenanceModal(true)
    }
  }

  const handleGameOpen = (game) => {
    if(!maintenance.active) {
      if ((user && (user.currentPlan === "Basic" || user.currentPlan === "basic")) || !user) {
        setBasicDenied(true)
      } else if (!userInfo.emailVerified) {
        // setComingSoon(true)
        setEmailDenied(true)
      } else {
        stream(game)
      }
    } else setMaintenanceModal(true)
  }

  // useEffect(() => {
  //   const newUser = localStorage.getItem('newAccount');
  //   if (newUser && newUser === "true") {
  //     setStartDisabled(true)
  //     setTutorialModal(true)
  //     setTimeout(() => {
  //       localStorage.setItem('newAccount', "false");
  //       setStartDisabled(false);
  //     }, 90000);
  //     return () => {
  //       localStorage.setItem('newAccount', "false");
  //     }
  //   } else setStartDisabled(false);
  // }, [])

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const newUser = localStorage.getItem('newAccount');
    const storedTime = localStorage.getItem("timer");
    const currentTime = new Date().getTime();
    if (storedTime && newUser) document.body.style.overflow = 'hidden';
    // localStorage.setItem("timer", currentTime.toString());
    const timeDifference = 90000 - (currentTime - parseInt(storedTime || "0"));
    setTimeLeft(Math.max(0, timeDifference));
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      // localStorage.setItem("timer", currentTime.toString());
      const timeDifference = 90000 - (currentTime - parseInt(storedTime || "0"));
      setTimeLeft(Math.max(0, timeDifference));
      if (timeDifference <= 0) {
        document.body.style.overflow = '';
        clearInterval(intervalId);
        localStorage.removeItem('timer');
        if (newUser && newUser === "true") setTutorialModal(true)
        localStorage.setItem("newAccount", "false");
      }
    }, 1000); // Update timer every second
    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor(timeLeft / 60000);
  const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

  const [isToggledOn, setIsToggledOn] = useState(false);

  const handleToggleButton = () => {
    setIsToggledOn(prevState => !prevState);
  };

  const [cookies, setCookie, removeCookie] = useCookies([
    "shutDownTimeout",
    "isShutdownClicked",
    "isStreamConnected",
    "refreshTimeout",
    "windowClosedAt",
  ]);

  useEffect(() => {
    const isStreamConnected = cookies["isStreamConnected"];
    const refreshTime = cookies["windowClosedAt"];
    if (isStreamConnected && refreshTime) {
      const currentTime = new Date().getTime();
      const startedAttimeDifference =
        55000 - (currentTime - parseInt(refreshTime || "0"));
      if (startedAttimeDifference > 0) {
        setTimeout(() => {
          const currentTime1 = new Date().getTime();
          setCookie("isShutdownClicked", currentTime1.toString(), {
            path: "/",
          });
        }, startedAttimeDifference);
      } else if (
        startedAttimeDifference + 30000 > 0 &&
        startedAttimeDifference <= 0
      ) {
        const currentTime1 = new Date().getTime();
        setCookie(
          "isShutdownClicked",
          (currentTime1 + startedAttimeDifference).toString(),
          {
            path: "/",
          }
        );
      } else {
        removeCookie("isStreamConnected", { path: "/" });
      }
    }
  }, []);

  return (
    <>

      {/* Maintenance Modal */}
      <Modal
        open={maintenanceModal}
        onClose={handleMaintenanceModalClose}
        className="ComingSoon-modal"
      >
        <Box sx={basicDeniedStyle1}>
          <Typography sx={{ textAlign: "center" }} variant="p">
          {maintenance.message ? maintenance.message : "Our service won't be available due to an emergency maintenance. Thank you for your patience and support."}
          {/* {maintenance.message ? maintenance.message : "The platform will be on maintenance from 12 (Noon) till 5 pm, on 7th October for server addition & hardware upgrade."} */}
          {/* Our service won't be available due to an emergency maintenance from 12:30PM to 2PM today (Wednesday). Thank you for your patience and support. */}
            {/* Our sincere apologies for the inconvenience caused. Users will not be able to connect to their PCs. We are currently investigating the issue. Thank you for your patience and support. */}
          </Typography>
        </Box>
      </Modal>


      {/* Tutorial Modal */}
      <Modal onClose={false} open={tutorialModal}>
        <Box sx={tutorialStyle}>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={6000}
            centerMode={false}
            className="dashboard-carousal"
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <Grid sx={{ alignItems: "center", height: "100%" }} container spacing={2}>
              <Grid className='dashboard-modal-img-grid' item xs={6}>
                <img
                  id="dash"
                  style={{ width: "100%", height: "100%" }}
                  alt="logo"
                  src={dashboardImg}
                />
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
                <p style={{ width: "80%" }}>
                  Hi! Welcome to AntCloud, Your account is now ready.
                  <br /><br />
                  This is your Dashboard.
                  <br /> <br />
                  From here, you can directly access your own PC & use it however you please.
                </p>
              </Grid>
            </Grid>
            <Grid sx={{ alignItems: "center", height: "100%" }} container spacing={2}>
              <Grid className='dashboard-modal-img-grid' item xs={6}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="logo"
                  src={gameImg}
                />
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={6}>
                <p style={{ width: "80%" }}>
                  From the games section, you can simply click on the pre-installed games & start playing.
                </p>
              </Grid>
            </Grid>
            <div style={{ width: "50%", margin: "auto", paddingTop: "2rem", height: "100%" }}>
              {/* <div > */}
              <Cancel onClick={() => setTutorialModal(false)} style={{ position: "absolute", right: "5%", top: "0", cursor: "pointer" }} />
              {/* </div> */}
              <h6>Shortcut Keys</h6>
              <br />
              <ul>
                <li><b style={{ color: "#22B9A6" }}>F10</b> - Toggle FullScreen Mode</li>
                <li><b style={{ color: "#22B9A6" }}>F9</b> - Toggle Stream Setting Menu</li>
              </ul>
              <br />
              <br />
              <p>
                <b>
                  Note: From the F9 section, you can change your video stream settings & shut down your PC as well.
                </b></p>
            </div>
          </Carousel>
        </Box>
      </Modal>

      {/* Coming Soon Modal */}
      <Modal
        open={comingSoon}
        onClose={handleClose}
        className='ComingSoon-modal'
      >
        <Box sx={style}>
          <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src={ComingSoon} />
        </Box>
      </Modal>

      {/* Plan Activating Modal */}
      <Modal
        open={openStartDisabled}
        onClose={handleOpenStartDisabledClose}
        className='ComingSoon-modal'
      >
        <Box sx={basicDeniedStyle}>
          <Typography sx={{ textAlign: "center" }} variant="p">
            Your Plan is being activated right now.
            <br /> <br />
            Please wait for a few minutes and then refresh your page to start playing.
          </Typography>
        </Box>
      </Modal>

      {/* Basic Denied Modal */}

      <Modal
        open={basicDenied}
        onClose={handleBasicDeniedClose}
        className='ComingSoon-modal'
      >
        <Box sx={basicDeniedStyle}>

          {user && user.renewDate
            ?
            <Typography sx={{ textAlign: "center" }} variant="p">
              Oops! It seems like your plan has expired.
              <br /> <br />
              Renew your plan now by visiting our pricing page from below Link.
            </Typography>
            :
            <Typography sx={{ textAlign: "center" }} variant="p">
              Oops! It seems like you're not on a paid plan right now.
              If you have recently purchased or renewed a plan, please wait for five minutes and then refresh the page for your plan to activate.
              <br /> <br />
              If you haven't purchased a plan, Please visit our pricing page from below Link.
            </Typography>
          }

          <br /> <br />
          <Link style={{ display: "block", width: "40%", margin: "auto" }} to="/pricing" className='btn-comm'>Pricing</Link>
        </Box>
      </Modal>

      {/* Email Denied Modal */}
      <Modal
        open={emailDenied}
        onClose={handleEmailDeniedClose}
        className='ComingSoon-modal'
      >
        <Box sx={basicDeniedStyle}>
          <Typography sx={{ textAlign: "center" }} variant="p">
            Oops! It seems like you haven't verified your email yet.
            <br /> <br />
            Please verify your email before starting the stream.
          </Typography>
        </Box>
      </Modal>

      {/* Not Allowed to Restart VM */}
      <Modal
        open={isStartedModal}
        onClose={() => setIsstartedModal(false)}
        className="ComingSoon-modal"
      >
        <Box sx={basicDeniedStyle1}>
          <Typography sx={{ textAlign: "center" }} variant="p">
            Please wait for a minute while your machine is being reset.
          </Typography>
        </Box>
      </Modal>

      <Box sx={{ width: '100%' }}>
        <section className={styles.PCSection}>
          <div className={styles.backgroundContainer}
            onClick={handleDesktopOpen}
          // onClick={() => setMaintenanceModal(true)}
          // onClick={handleOpen}
          // onClick={() => stream('desktop')}
          >
            <img src={bgImage} alt="Background" style={timeLeft > 0 ? { opacity: 0.3 } : {}} className={styles.backgroundImage} />
            <h1 style={timeLeft <= 0 ? { position: "relative" } : {}} className={styles.PCModeHeading}>Welcome to your Dashboard.</h1>
            <p style={timeLeft <= 0 ? { position: "relative", fontWeight: "bolder" } : {}} className={styles.PCModeText}> Click anywhere to access your very own Virtual PC</p>

            {timeLeft > 0 ? (
              <div
                className={styles.glightboxVideo}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: "60%",
                  ["@media (min-width:992px)"]: {
                    width: "40%"
                  },
                  color: "#fff",
                  background: '#212121',
                  opacity: "1",
                  padding: "30px 40px",
                  border: '2px solid #000',
                  boxShadow: 24,
                  cursor: "default"
                }}
              >
                <h5>
                  Thank you for your payment.<br />
                  Your account is being setup right now. <br /> <br />
                  It'll be available in <span style={{ color: "#22B9A6" }}>{`0${minutes}`}:
                    {`${seconds > 9 ? "" : "0"}${seconds}`} minutes </span>
                </h5>
              </div>
            ) : (
              <div style={{ position: "absolute", top: "61%", left: "49.3%", transform: "translate(-50%, -50%)" }}>
                <PowerButton
                  isToggledOn={isToggledOn}
                  onToggleButton={handleToggleButton}
                />
                {/* <div style={playButton ? { display: "flex", flexDirection: "column", alignItems: "center", border: "1px solid #111", borderRadius: "10px" } : { display: "none" }} className={styles.glightboxVideo}> */}
                {/* <a onClick={toggleState} onMouseEnter={onReady} onMouseLeave={offReady} onTransitionEnd={finishState} className="startButton off ready">
                  <span className="sc sc-right"></span>
                  <span className="sc sc-left"></span>
                  <span className="bar"></span>
                </a> */}
                {/* <button style={{ color: "#111", border: "none", backgroundColor: "transparent" }} className={`btn-comm ${styles.startBtn}`}>
                  Start Your PC 
                </button>
                <PowerSettingsNewIcon fontSize='large' sx={{ color: "#111"  }} /> */}
                {/* <svg
                  width="100"
                  height="100"
                  viewBox="0 0 131 131"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ opacity: "0.4" }}
                    className={styles.innerCircle}
                    d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z"
                    fill="black"
                  ></path>
                  <circle
                    style={{ opacity: "0.4" }}
                    className={styles.outerCircle}
                    cx="65.5"
                    cy="65.5"
                    r="64"
                    stroke="black"
                  ></circle>
                  <path
                    style={{ opacity: "1" }}
                    className={styles.play}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M60 76V57L77 66.7774L60 76Z"
                    fill="#57FB54"
                  ></path>
                </svg> */}
                {/* </div> */}
              </div>
            )}
            {/* <div className={styles.glightboxVideo}>
              <svg width="100" height="100" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path style={{ opacity: "0.4" }} className={styles.innerCircle} d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="black"></path>
                <circle style={{ opacity: "0.4" }} className={styles.outerCircle} cx="65.5" cy="65.5" r="64" stroke="black"></circle>
                <path style={{ opacity: "1" }} className={styles.play} fillRule="evenodd" clipRule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#57FB54"></path>
              </svg>
            </div> */}
          </div>

        </section>
        <br />
        <h3>Games</h3>
        <p className={styles.dashboardText}>Click & play games from our own library. No need to install!</p>
        <br />
        <Grid container rowSpacing={3} sx={{ position: "relative", marginLeft: "1%", marginRight: "1%", paddingRight: "2rem", paddingLeft: "2rem", paddingBottom: "2rem" }} columnSpacing={{ xs: 1, sm: 1, md: 3 }}>
        {/* <div style={{ position: "absolute", zIndex:"99", userSelect: "none", top: "30%", left: "35%", right: "50%", padding: "2rem 2rem 0", textAlign: "center", backgroundColor: "#212121", border: "1px solid white", height: "7rem", width: "35rem", bottom: 0 }}>
           <h4>Note: Games are under maintenance right now.</h4> 
        </div> */}
          {
            games.map(game => {
              return <Grid item="true" key={game.gameId} xs={12} md={3}>
                <Card key={game.gameId}
                  onClick={() => handleGameOpen(game.gameId)}
                  // onClick={() => setMaintenance(true)}
                  // onClick={handleOpen}
                  // onClick={() => stream(game.gameId)}
                  sx={{ backgroundColor: "transparent", cursor: "pointer" }}
                >
                  <img className={styles.bleh} src={require(`../../images/${game.gameId}.webp`)} />
                </Card>
              </Grid>
            })
          }
        </Grid>

        {/* Profile Section */}

        <div className={profileStyle.profile}>
          <h1 className={profileStyle.profile__header}>Profile </h1>
          <div className={profileStyle.profile__box}>
            <div
              className={`${profileStyle.profile__content} ${menuOpen && profileStyle.menu_open}`}
            >
              <span
                onClick={() => {
                  setMenuOpen(true);
                }}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </span>
              {content[menuItem]}
              {/* {user && user.upcomingPlans ? `<p>Your upcoming ${user.upcomingPlans[0].planName} plan will be activated on ${getDateString(user.renewDate)} </p>` : null} */}
              {user && user.currentPlan.toLowerCase() == "basic" && user.renewDate ? <p>Your Plan has expired! Renew your plan today to enjoy uninterrupted playtime. </p> : <div />}
            </div>

            <div
              className={`${profileStyle.profile__menu} ${menuOpen && profileStyle.menu_open
                }`}
            >
              <span
                onClick={() => {
                  setMenuOpen(false);
                }}
                className={`${!menuOpen && profileStyle.none}`}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
              {Object.keys(content).map((item, idx) => (
                <p

                  className={`${profileStyle.profile__menu_item} ${menuItem === "Account" && menuItem === item ? profileStyle.selected_item_left : menuItem === "Billing" && menuItem === item ? profileStyle.selected_item_right : ""
                    }`}
                  key={idx}
                  onClick={() => setMenuItem(item)}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </Box>

    </>

  );
}