import React, { useState, useEffect } from "react"
import latencyIcon from '../images/latency-icon.webp';
import gamePIcon from '../images/gameP-icon.webp';
import pcPIcon from '../images/pcP-icon.webp';
import plansPIcon from '../images/plansP-icon.webp';
import audioPIcon from '../images/audioP-icon.webp';
import profilePIcon from '../images/profileP-icon.webp';
import FAQS from '../assets/faq.json';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { TextareaAutosize } from "@mui/material"
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import SupportDataQ from '../assets/supportQ.json';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function Support() {
    const navigate = useNavigate();
    const [supportData, setSupportData] = useState({
        internetConnection: "",
        issueRelatedTo: [],
        issue: "",
        source: "Browser"
    })
    const [submissionError, setSubmissionError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [issueRelatedToSelectOpen, setIssueRelatedToSelectOpen] = useState(false);
    const { userToken, loggedIn } = useSelector(state => state.auth)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setIssueRelatedToSelectOpen(false)
        if (name === "issue") setSubmissionError(false);
        setSupportData({ ...supportData, [name]: value });
    };

    const submitSupportRequest = async () => {
        let str = supportData.issue;
        str = str.trim();
        const words = str.split(/\s+/);
        if (words.length < 25) {
            setSubmissionError('Please provide a detailed description of your issue in atleast 25 words');
        } else {
            setLoading(true)
            const supportRequest = await fetch('https://api.antcloud.co/api/support/create', {
                method: "POST",
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': `JWT ${userToken}`
                },
                body: JSON.stringify(supportData)
            })
            if (supportRequest) {
                setLoading(false);
                setSupportData({
                    internetConnection: "",
                    issueRelatedTo: [],
                    issue: "",
                    source: "Browser"
                })
                const supportResponse = await supportRequest.json();
                if (supportRequest.status === 200) setMessage('Support Request Created!')
                else if (supportResponse.message === "You are not allowed to perform this action") setSubmissionError('Please Login again!')
                else setSubmissionError('Something went wrong!')
                // console.log(supportRequest)
                // console.log(supportResponse)
            }
        }
    }

    useEffect(() => {
        if (!loggedIn) navigate('/signin')
    }, [])

    return (
        <>
            {/* Banner Sec */}
            <section className="inner-banner-sec support-banner-sec" >
                <div className="" data-aos="fade-up" data-aos-delay="300">
                    <div className="container custom-container" >
                        <div className="banner-caption text-center">
                            <h1 className="main-heading main-heading-md">Support</h1>
                            <p className="subHeading-text">Please select any of the categories below for any issues that you may be facing or contact us directly</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Support Content */}
            <section className="support-content">
                <div className="container custom-container" data-aos="fade-up" data-aos-delay="320">
                    <div className="row">

                        <div className="col-lg-4 col-md-4 col-sm-12 support-content-box" data-bs-toggle="modal" data-bs-target="#latencyModal">
                            <div className="support-content-boxInn">
                                <img src={latencyIcon} alt="" />
                                <h3 className="box-heading">Latency</h3>
                                <p>Frame drops, stream issues,etc</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 support-content-box" data-bs-toggle="modal" data-bs-target="#controlsModal">
                            <div className="support-content-boxInn">
                                <img src={gamePIcon} alt="" />
                                <h3 className="box-heading">Controls</h3>
                                <p>Controller support, keys not working etc</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 support-content-box" data-bs-toggle="modal" data-bs-target="#pcModal">
                            <div className="support-content-boxInn">
                                <img src={pcPIcon} alt="" />
                                <h3 className="box-heading">PC Mode</h3>
                                <p>How to use, customisations,etc</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 support-content-box" data-bs-toggle="modal" data-bs-target="#plansModal">
                            <div className="support-content-boxInn">
                                <img src={plansPIcon} alt="" />
                                <h3 className="box-heading">Plans</h3>
                                <p>Pricing, subscription issues, etc</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 support-content-box" data-bs-toggle="modal" data-bs-target="#audioModal">
                            <div className="support-content-boxInn">
                                <img src={audioPIcon} alt="" />
                                <h3 className="box-heading">Audio</h3>
                                <p>Missing audio, headphone support,etc</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 support-content-box" data-bs-toggle="modal" data-bs-target="#profileModal">
                            <div className="support-content-boxInn">
                                <img src={profilePIcon} alt="" />
                                <h3 className="box-heading">Profile</h3>
                                <p>Updating email id, passwords,etc</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Us Section */}
            <section className="contact-content-sec">
                <div className="container custom-container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 contact-info" data-aos="fade-up" data-aos-delay="300">
                            <div className="contact-infoInn">
                                <h2 className="section-heading">Contact our support team</h2>
                                <p className="subHeading-text">
                                    Fill out the contact form, and we'll get back to you as soon as possible. Alternatively, you can reach us through our provided phone number or email address.
                                </p>
                                <ul>
                                    <li>
                                        <p><i className="fas fa-map-marker-alt" style={{ marginRight: "0.6rem" }}></i> Ant-Esports Pvt. Ltd
                                            Z-41, Okhla Phase II, New Delhi, 110020</p>
                                    </li>
                                    {/* <li>
                                        <p> <a href="tel:+919818037004" style={{ color: "#fff" }}><i className="fas fa-phone-alt" style={{ marginRight: "0.6rem" }}></i> +91-9818037004</a> </p>
                                    </li> */}
                                    <li>
                                        <p> <a href="mailto:support@antcloud.co" style={{ color: "#fff" }}><i className="fas fa-envelope" style={{ marginRight: "0.6rem" }}></i> support@antcloud.co</a> </p>
                                    </li>
                                    <li>
                                        <p> <i className="fas fa-clock" style={{ marginRight: "0.6rem" }}></i> Timing: 10 AM - 6 PM</p>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-5 contact-form" data-aos="fade-up" data-aos-delay="300">
                            <div className="contact-form-box">
                                <h2 className="section-heading">Send us a message</h2>
                                <form style={{ display: "flex", flexDirection: "column" }}>
                                    <FormControl sx={{ mb: "1rem" }}>
                                        <InputLabel
                                            id="demo-simple-select-helper-label"
                                            sx={{
                                                color: "#fff",
                                                '&.Mui-focused': {
                                                    color: "#22B9A6",
                                                },
                                            }}
                                        >Issue Related To </InputLabel>
                                        <Select
                                            value={supportData.issueRelatedTo}
                                            label="Issue Related To"
                                            onChange={handleChange}
                                            MenuProps={{
                                                onClose: () => setIssueRelatedToSelectOpen(false),
                                            }}
                                            open={issueRelatedToSelectOpen}
                                            onOpen={() => setIssueRelatedToSelectOpen(true)}
                                            name="issueRelatedTo"
                                            multiple
                                            sx={{
                                                color: "#fff",
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#22B9A6',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white'
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white'
                                                }
                                            }}
                                        >

                                            <MenuItem
                                                // sx={{ '&.Mui-selected': {
                                                //     backgroundColor: "22B9A6"
                                                // } }} 
                                                value="Latency">Latency</MenuItem>
                                            <MenuItem value="Game Controls">Game Controls</MenuItem>
                                            <MenuItem value="Game Library">Game Library</MenuItem>
                                            <MenuItem value="Others">Others</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={supportData.issueRelatedTo.includes('Latency') ? { mb: "1rem", display: "block" } : { display: "none" }}>
                                        <FormLabel
                                            sx={{
                                                color: "#fff",
                                                '&.Mui-focused': {
                                                    color: "#fff",
                                                },
                                            }}
                                            id="demo-row-radio-buttons-group-label">
                                            Internet Connection:
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-internetConnection-radio-buttons-group"
                                            onChange={handleChange}
                                            defaultValue="WiFi"
                                        >
                                            <FormControlLabel
                                                value="WiFi"
                                                control={
                                                    <Radio
                                                        name="internetConnection"
                                                        sx={{
                                                            color: "#fff",
                                                            '&.Mui-checked': {
                                                                color: "#22B9A6",
                                                            },
                                                        }}
                                                    />}
                                                label="Wi-Fi"
                                            />
                                            <FormControlLabel
                                                value="LAN"
                                                control={
                                                    <Radio
                                                        name="internetConnection"
                                                        sx={{
                                                            color: "#fff",
                                                            '&.Mui-checked': {
                                                                color: "#22B9A6",
                                                            },
                                                        }}
                                                    />}
                                                label="LAN" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel
                                            sx={{
                                                color: "#fff",
                                                '&.Mui-focused': {
                                                    color: "#fff",
                                                },
                                                mb: "1rem"
                                            }}
                                            id="demo-row-radio-buttons-group-label">
                                            Your Issue Details:
                                        </FormLabel>
                                        <TextareaAutosize name="issue" onChange={handleChange} value={supportData.issue} className="form-control" minRows={3} cols={2} placeholder="Describe your issue here in atleast 25 words." />
                                    </FormControl>
                                    <p style={{ color: "#4CBB17", textAlign: "center" }}>{message ? message : ''}</p>
                                    <p style={{ color: "#f08080", textAlign: "center" }}> {submissionError ? submissionError : ''} </p>
                                    {/* <label></label>
                                    <textarea rows="3" cols="2" className="form-control" placeholder="Your text"></textarea> */}
                                    <button disabled={supportData.issueRelatedTo.length === 0 || supportData.issue === "" || loading} onClick={submitSupportRequest} type="button" className="btn-comm btn-lg">{loading ? 'Loading' : 'Submit'}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modals */}
            {/* Latency Modal */}
            <div className="modal fade support-qna-modal" id="latencyModal" tabIndex="-1" aria-labelledby="latencyModal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Latency</h5>
                            <button type="button" className="btn-closeModal" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="accordion modal-body" id="latencyAccordion">
                            <div className="row justify-content-center faqs-row-sec mt-0">
                                <div className="col-lg-12 faqs-cols" >
                                    {SupportDataQ.latency.map((faq, index) => {
                                        return (
                                            <div key={index} className="faqs-support-row accordion-item">
                                                <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#faq-section1-${index}`} aria-expanded="true" aria-controls={`faq-section1-${index}`}>
                                                    <h3 className="box-heading">{faq.ques}</h3>
                                                    <i className="fas fa-angle-double-down"></i>
                                                </div>
                                                <div id={`faq-section1-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#latencyAccordion">
                                                    <p>
                                                        {faq.ans}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {/* <div className="faqs-support-row accordion-item" data-aos="fade-up" data-aos-delay="300">
                                        <div className="faqs-support-ques">
                                            <h3 className="box-heading">What does 'PC Mode' mean?</h3>
                                            <i className="fas fa-angle-double-down"></i>
                                        </div>
                                        <div className="faqs-support-ans">
                                            <p>
                                                'PC Mode' offers users their own desktop, which they can customise however they want with their own games, softwares or use it any way they want to, from whereever they wish to.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="faqs-support-row accordion-item" data-aos="fade-up" data-aos-delay="300">
                                        <div className="faqs-support-ques">
                                            <h3 className="box-heading">Are the listed games free?</h3>
                                            <i className="fas fa-angle-double-down"></i>
                                        </div>
                                        <div className="faqs-support-ans">
                                            <p>
                                                Yes! All games listed on our website are free to use & need not be purchased seperately. Just click & start playing!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="faqs-support-row accordion-item" data-aos="fade-up" data-aos-delay="300">
                                        <div className="faqs-support-ques">
                                            <h3 className="box-heading">What are the minmum requirements?</h3>
                                            <i className="fas fa-angle-double-down"></i>
                                        </div>
                                        <div className="faqs-support-ans">
                                            <p>
                                                We recommend a 50 Mbps broadband connection for a 1080p stream &amp; a 100 Mbps broadband connection for a 4K stream. We also highly recommend a LAN or a 5Ghz WiFi connection. For the requirements per system, check the downloads page.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="faqs-support-row accordion-item" data-aos="fade-up" data-aos-delay="300">
                                        <div className="faqs-support-ques">
                                            <h3 className="box-heading">What happens to the game data?</h3>
                                            <i className="fas fa-angle-double-down"></i>
                                        </div>
                                        <div className="faqs-support-ans">
                                            <p>
                                                Many people has the notion that enlightenment is one state. Many also believe that when it is attained, a person is forever in that state.For your necessary discernment. Thank you for reading.
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Controls Modal */}
            <div className="modal fade support-qna-modal" id="controlsModal" tabIndex="-1" aria-labelledby="controlsModal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Controls</h5>
                            <button type="button" className="btn-closeModal" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="accordion modal-body" id="controlsModal">
                            <div className="row justify-content-center faqs-row-sec mt-0">
                                <div className="col-lg-12 faqs-cols" >
                                    {SupportDataQ.controls.map((faq, index) => {
                                        return (
                                            <div key={index} className="faqs-support-row accordion-item">
                                                <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#control-section1-${index}`} aria-expanded="true" aria-controls={`control-section1-${index}`}>
                                                    <h3 className="box-heading">{faq.ques}</h3>
                                                    <i className="fas fa-angle-double-down"></i>
                                                </div>
                                                <div id={`control-section1-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#controlsModal">
                                                    <p>
                                                        {faq.ans}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* pcModal */}
            <div className="modal fade support-qna-modal" id="pcModal" tabIndex="-1" aria-labelledby="pcModal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">PC</h5>
                            <button type="button" className="btn-closeModal" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="accordion modal-body" id="pcModalAccordion">
                            <div className="row justify-content-center faqs-row-sec mt-0">
                                <div className="col-lg-12 faqs-cols" >
                                    {SupportDataQ.pcMode.map((faq, index) => {
                                        return (
                                            <div key={index} className="faqs-support-row accordion-item">
                                                <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#pc-section1-${index}`} aria-expanded="true" aria-controls={`pc-section1-${index}`}>
                                                    <h3 className="box-heading">{faq.ques}</h3>
                                                    <i className="fas fa-angle-double-down"></i>
                                                </div>
                                                <div id={`pc-section1-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#pcModalAccordion">
                                                    <p>
                                                        {faq.ans}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Plans Modal */}
            <div className="modal fade support-qna-modal" id="plansModal" tabIndex="-1" aria-labelledby="plansModal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Plans</h5>
                            <button type="button" className="btn-closeModal" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="accordion modal-body" id="planModalAccordion">
                            <div className="row justify-content-center faqs-row-sec mt-0">
                                <div className="col-lg-12 faqs-cols" >
                                    {SupportDataQ.plans.map((faq, index) => {
                                        return (
                                            <div key={index} className="faqs-support-row accordion-item">
                                                <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#plans-section1-${index}`} aria-expanded="true" aria-controls={`plans-section1-${index}`}>
                                                    <h3 className="box-heading">{faq.ques}</h3>
                                                    <i className="fas fa-angle-double-down"></i>
                                                </div>
                                                <div id={`plans-section1-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#planModalAccordion">
                                                    <p>
                                                        {faq.ans}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Audio Modal */}
            <div className="modal fade support-qna-modal" id="audioModal" tabIndex="-1" aria-labelledby="audioModal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Audio</h5>
                            <button type="button" className="btn-closeModal" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="accordion modal-body" id="audioAccordion">
                            <div className="row justify-content-center faqs-row-sec mt-0">
                                <div className="col-lg-12 faqs-cols" >
                                    {SupportDataQ.audio.map((faq, index) => {
                                        return (
                                            <div key={index} className="faqs-support-row accordion-item">
                                                <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#audio-section1-${index}`} aria-expanded="true" aria-controls={`audio-section1-${index}`}>
                                                    <h3 className="box-heading">{faq.ques}</h3>
                                                    <i className="fas fa-angle-double-down"></i>
                                                </div>
                                                <div id={`audio-section1-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#audioAccordion">
                                                    <p>
                                                        {faq.ans}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Profile Modal */}
            <div className="modal fade support-qna-modal" id="profileModal" tabIndex="-1" aria-labelledby="profileModal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Profile</h5>
                            <button type="button" className="btn-closeModal" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="accordion modal-body" id="profileAccordion" >
                            <div className="row justify-content-center faqs-row-sec mt-0">
                                <div className="col-lg-12 faqs-cols" >
                                    {SupportDataQ.profile.map((faq, index) => {
                                        return (
                                            <div key={index} className="faqs-support-row accordion-item">
                                                <div className="faqs-support-ques" data-bs-toggle="collapse" data-bs-target={`#profile-section1-${index}`} aria-expanded="true" aria-controls={`profile-section1-${index}`}>
                                                    <h3 className="box-heading">{faq.ques}</h3>
                                                    <i className="fas fa-angle-double-down"></i>
                                                </div>
                                                <div id={`profile-section1-${index}`} className="accordion-collapse collapse faqs-support-ans" data-bs-parent="#profileAccordion">
                                                    <p>
                                                        {faq.ans}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}