import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';
import LatestNews from "../Components/LatestNews"
import Parallax from "../Components/Parallax"
import { Link } from 'react-router-dom';

export default function Download() {
    const [allowed, setAllowed] = useState(false);
    const user = useSelector(state => state.user.userDetails);

    useEffect(() => {
        const blockedIds = ['65f574b1136fe2f30744ba7e', "6646dff9465ec18bde7549a3", '6645ad2d0f6deed1587dcb93', '6634dd0d9a611aeb3d9b781c', "6644605552c2213b4777dd13",  '669a153e8944e959ce1d0e7e', '661f846dfb6c209ae828e4da', '67065f2bec97840fc182288d', '672723843adc99366362da44', '675815f4e3727fa9011d9970', '6655fd821313f589ef10f65f', '66e6c156cf6ca3677a6aca09', '6740215e0853fc1aadc70fff', '66bcba67a629378d9115159a', '668ebb771d4184b9c86b3eca', '66dae4521cbe151f4fcb7c01', '6727cd01b26ff6993aadef0a', '66cb3f7ea370c138eec27847', '66b338656c6400609f197968', '67177f30a894a7a4b8ce8776', '66e7dc6cf1cfb02249c1b40c', '66a51e56f76521775086173b', '66d9557d314c93cd7ec4e6c3', '6706c0618a0975f757d27f0d', '66cc788cbfa5f1f6439e6711'];
        if (user && blockedIds.includes(user.id)) setAllowed(true);
    }, [user])
    return (
        <>
            {/* Banner Section */}
            <section className="inner-banner-sec support-banner-sec" >
                <div className="" data-aos="fade-up" data-aos-delay="300">
                    <div className="container custom-container" >
                        <div className="banner-caption text-center">
                            <h1 className="main-heading main-heading-md">Download</h1>
                            {/* <p className="subHeading-text">Duis at volutpat orci facilisis risus nunc consequat dui volutpat et. </p> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* Three Box Section */}
            <section className="points-box-sec downloadsP-content">
                <div className="container custom-container">
                    <h2 className="section-heading" data-aos="fade-up" data-aos-delay="300">Where Can You Play?</h2>
                    <p className="subHeading-text" data-aos="fade-up" data-aos-delay="320">Antcloud is now available via all the below mentioned devices!</p>
                    <div className="row">
                        {/* <div className="col-lg-4 points-box image-parallax">
                        <div className="points-boxInn" data-aos="fade-up" data-aos-delay="300">
                                <div className="points-box-img">
                                    <i className="fas fa-globe animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored">BROWSER</h3>
                                    <h3 className="box-heading box-heading-sm">Play on your browser!</h3>
                                    <p>Chrome or Edge recommended </p>
                                    <a href="#" className="btn-comm">Choose <i className="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div> */}

                         <Parallax>
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="300">
                                <div className="points-box-img">
                                    <i className="fas fa-globe animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored">BROWSER</h3>
                                    <h3 className="box-heading box-heading-sm">Play on your browser! (PC/Laptop/Mac)</h3>
                                    <p>Windows 10 and above supported.</p>
                                    <p>Chrome or Edge recommended </p>
                                    <Link to="/signin" className="btn-comm">Start Playing <i className="fas fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </Parallax>

                        <Parallax>
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="320">
                                <div className="points-box-img">
                                    <i className="fas fa-mobile-alt animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored">MOBILE APP</h3>
                                    <h3 className="box-heading box-heading-sm" style={{ paddingBottom: "1.5rem" }}>Play on your Android smartphone!</h3>
                                    <p>Android 8 and above supported.</p>
                                    <p className="text-highlights">iOS suppport coming soon!</p>
                                    {/* <p style={{ marginTop: "1rem" }} className="text-highlights">Coming soon!</p> */}
                                    {/* <button style={{ backgroundColor: "transparent" }} className="btn-comm coming-soon-btn text-highlights">Coming Soon <i className="fas fa-chevron-right"></i></button> */}
                                    <a href="https://play.google.com/store/apps/details?id=com.antcloud.app" className="btn-comm">Start Playing <i className="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </Parallax>

                       <Parallax>
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="340">
                                <div className="points-box-img">
                                    <i className="fab fa-windows animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored"> WINDOWS APP</h3>
                                    <h3 className="box-heading box-heading-sm" style={allowed ? { paddingBottom: "1.5rem" } : { paddingBottom: "7rem" }}>Download our app!</h3>
                                    {allowed
                                        ?
                                        <>
                                            <p style={{ paddingBottom: "2.5rem" }}>Windows 10 and above supported.</p>
                                            <a
                                                href="https://antplay-client-1f85c53a.s3.ap-south-1.amazonaws.com/windows/Antcloud.msi"
                                                className="btn-comm"
                                            >
                                                Download Now
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </>
                                        : <button style={{ backgroundColor: "transparent", '&:hover': { color: "#45d4b8" } }} className="btn-comm coming-soon-btn text-highlights">Coming Soon <i className="fas fa-chevron-right"></i></button>
                                    }
                                    {/* <button style={{ backgroundColor: "transparent", '&:hover': { color: "#45d4b8" } }} className="btn-comm coming-soon-btn text-highlights">Coming Soon <i className="fas fa-chevron-right"></i></button> */}
                                    {/* <p>Windows 10 & above</p> */}
                                    {/* <p className="text-highlights">Coming soon!</p> */}
                                    {/* <a href="#" className="btn-comm">Choose <i className="fas fa-chevron-right"></i></a> */}
                                </div>
                            </div>
                        </Parallax>
                    </div>



                    <div className="notes-sec mt-5" data-aos="fade-up" data-aos-delay="300">
                        {/* <p>
                            Please make sure your system has the <a href="#">recomended specs as mentioned here</a>.
                        </p> */}
                        <p style={{ marginBottom: 0, paddingBottom: "1rem" }}>
                            By using our platform & our applications, you are agreeing to it's <Link to="/privacyPolicy">End User License Agreement</Link>.
                        </p>
                    </div>
                </div>
            </section>

            {/* Latest News */}

            <LatestNews />
        </>
    )
}