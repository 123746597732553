import { useState, useEffect } from "react";
import Spinner from "./spinner";

function Loading({ game, vmstatus, mobileStream }) {
    const [timer, setTimer] = useState(300);
    useEffect(() => {
        let checkTime;
        if(vmstatus === "starting") {
            checkTime = setInterval(() => {
                setTimer((pre) => pre - 1)
            }, 1000)
        }
        return () => {
            clearInterval(checkTime)
        }
    }, [vmstatus])

    return (
        vmstatus === "starting" ?
            <div>
                <h3
                    style={{ textAlign: "center", marginTop: "50px" }}
                >
                    Your PC is starting<br />
                    This may take upto < span style={{ color: "#22B9A6" }
                    }> {`0${Math.trunc(timer / 60)}`}:
                        {`${timer % 60 > 9 ? "" : "0"}${timer % 60}`} minutes </span > < br /> <br />
                    {/* This may take upto 5 minutes<br /><br /> */}
                    {
                        !mobileStream && <p> Note: Press F10 to go fullscreen</p>
                    }
                    {/* <p> Note: Press F10 to go fullscreen</p> */}
                </h3>
                <Spinner />
            </div>
            : vmstatus === "done" ?
                <div>
                    <h3
                        style={{ textAlign: "center", marginTop: "50px" }}
                    >
                        Loading Stream<br />
                        This may take upto 30 seconds.<br /><br />
                        {
                            !mobileStream && <p> Note: Press F10 to go fullscreen</p>
                        }
                        {/* <p> Note: Press F10 to go fullscreen</p> */}
                    </h3>
                    <Spinner />
                </div> : game === "desktop" ?
                    <div>
                        <h3
                            style={{ textAlign: "center", marginTop: "50px" }}
                        >
                            Sending Boot Request<br />
                            Please refresh your page and try again if it takes more than 30 seconds.
                            <br /><br />
                            {
                                !mobileStream && <p> Note: Press F10 to go fullscreen</p>
                            }
                            {/* <p> Note: Press F10 to go fullscreen</p> */}
                        </h3>
                        <Spinner />
                    </div>
                    : <div style={{ height: "100vh", backgroundColor: "black", paddingTop: "10%" }}>
                        <h3
                            style={{ textAlign: "center" }}
                        >
                            Loading Please wait <br />
                            Please refresh your page and try again if it takes more than 30 seconds.
                            <br /><br />
                            {
                                !mobileStream && <p> Note: Press F10 to go fullscreen</p>
                            }
                            {/* <p> Note: Press F10 to go fullscreen</p> */}
                        </h3>
                        <Spinner topPosition={50} />
                    </div>
    );
}

export default Loading;