import { useState, useEffect } from 'react';
import styles from './onScreenKeyboard.module.css'
import { JoystickDiv } from './gamepadOverlay.styles';
import { Joystick } from 'react-joystick-component';

function OnScreenMouse({ setMouseBtnMessage, setMouseMoveMessage, marginLeft, marginTop }) {
  const [RMBClicked, setRMBClicked] = useState(false);
  const [LMBClicked, setLMBClicked] = useState(false);

  const videoElem = document.getElementById('videoElem');
  let rect = videoElem.getBoundingClientRect();

  const [pointerPosition, setPointerPosition] = useState({ x: rect.width / 2, y: rect.height / 2 });
  const [joystickData, setJoystickData] = useState({ x: 0, y: 0 });
  const [intervalId, setIntervalId] = useState(null);

  // const handleMove = (event) => {
  //   let mouseX = mouseStates.mouseX;
  //   let mouseY = mouseStates.mouseY;
  //   let videoElem = document.getElementById('videoElem');
  //   let x, y;
  //   console.log(event)
  //   if (event.x > 0.1 || event.x < -0.1) {
  //     let temp = mouseStates.mouseX + 15 * event.x;
  //     console.log(temp, marginLeft)
  //     if (temp >= -20 && temp <= window.innerWidth - marginLeft) {
  //       mouseX = Math.round(temp);
  //     }
  //   }
  //   if (event.y > 0.1 || event.y < -0.1) {
  //     let temp = mouseStates.mouseY - 15 * event.y;
  //     if (temp >= -20 && temp <= window.innerHeight) {
  //       mouseY = Math.round(temp);
  //     }
  //   }
  //   x = (mouseX) / window.innerWidth;
  //   y = (mouseY - marginLeft) / window.innerHeight;
  //   // x = mouseX / window.innerWidth;
  //   // y = mouseY / window.innerHeight;
  //   // let message = {
  //   //   id: 'mousemove',
  //   //   x,
  //   //   y
  //   // }
  //   let message = {
  //     id: 'mousemove',
  //     x: x / videoElem.clientWidth,
  //     y: y / videoElem.clientHeight
  //   }
  //   setMouseMoveMessage(JSON.stringify(message));
  //   setMouseStates({ mouseX, mouseY })

  // }

  // const handleMove = (event) => {
  //   // let mouseX = mouseStates.mouseX;
  //   // let mouseY = mouseStates.mouseY;
  //   let videoElem = document.getElementById('videoElem');
  //   let rect = videoElem.getBoundingClientRect(); // Get the video element's position and size
  //   console.log("Video Element Rect:", rect);

  //   // Calculate new mouseX and mouseY based on joystick movement
  //   let newMouseX = mouseX + 15 * event.x;
  //   let newMouseY = mouseY - 15 * event.y;

  //   console.log("New Mouse X:", newMouseX, "New Mouse Y:", newMouseY);

  //   // Constrain mouseX and mouseY within the video element's bounds
  //   mouseX = Math.max(rect.left, Math.min(newMouseX, rect.right));
  //   mouseY = Math.max(rect.top, Math.min(newMouseY, rect.bottom));

  //   // Normalize the mouse coordinates relative to the video element's dimensions
  //   let x = (mouseX - rect.left) / rect.width;
  //   let y = (mouseY - rect.top) / rect.height;

  //   // Send the normalized coordinates
  //   let message = {
  //     id: 'mousemove',
  //     x: x,
  //     y: y
  //   };

  //   setMouseMoveMessage(JSON.stringify(message));
  //   setMouseStates({ mouseX, mouseY });
  // };



  // const handleMove = (event) => {
  //   let mouseX = mouseStates.mouseX;
  //   let mouseY = mouseStates.mouseY;
  //   let videoElem = document.getElementById('videoElem')

  //   // Calculate new mouse coordinates
  //   let tempX = mouseX + 15 * event.x;
  //   let tempY = mouseY - 15 * event.y;

  //   // Constrain mouseX within the screen width
  //   mouseX = Math.max(-10, Math.min(tempX, window.innerWidth - (window.innerWidth * 0.25)));

  //   // Constrain mouseY within the screen height
  //   mouseY = Math.max(-10, Math.min(tempY, window.innerHeight - (window.innerHeight * 0.25)));

  //   // Update state
  //   setMouseStates({ mouseX, mouseY });

  //   // Send message
  //   let x = (mouseX - marginLeft) / window.innerWidth;
  //   let y = (mouseY - marginLeft) / window.innerHeight;

  //   // let message = {
  //   //   id: 'mousemove',
  //   //   x,
  //   //   y
  //   // };

  //   let message = {
  //     id: 'mousemove',
  //     x: x / videoElem.clientWidth,
  //     y: y / videoElem.clientHeight
  //   }

  //   setMouseMoveMessage(JSON.stringify(message));
  // };

  const sendCoordinatesToStream = (pointerX, pointerY, videoSize) => {
    const { width: videoWidth, height: videoHeight } = videoSize;

    // Calculate relative pointer position within the video
    const relativeX = pointerX;
    const relativeY = pointerY - marginTop;

    // Normalize coordinates
    const normalizedX = relativeX / videoWidth;
    const normalizedY = relativeY / videoHeight;

    // Ensure coordinates are within the [0, 1] range
    const clampedX = Math.max(0, Math.min(normalizedX, 1));
    const clampedY = Math.max(0, Math.min(normalizedY, 1));

    // Send coordinates via WebRTC stream
    const message = {
      id: 'mousemove',
      x: clampedX,
      y: clampedY
    };
    setMouseMoveMessage(JSON.stringify(message));
  };


  const updatePointerPosition = (deltaX, deltaY) => {
    setPointerPosition(prevPosition => {
      const newX = Math.max(0, Math.min(prevPosition.x + deltaX, rect.width));
      const newY = Math.max(0, Math.min(prevPosition.y - deltaY, rect.height - 5));

      sendCoordinatesToStream(newX, newY, rect);
      return { x: newX, y: newY };
    });
  };

  useEffect(() => {
    window.updatePointerPosition = updatePointerPosition;
  }, [rect]);

  // Function to continuously update pointer based on joystick data
  const updatePointer = () => {
    const { x, y } = joystickData;

    if (x !== 0 || y !== 0) {
      const movementSpeed = 15;
      const deltaX = x * movementSpeed;
      const deltaY = y * movementSpeed;

      updatePointerPosition(deltaX, deltaY);
    }
  };

  const handleMove = (event) => {
    setJoystickData({ x: event.x, y: event.y });
  };

  const handleStop = () => {
    setJoystickData({ x: 0, y: 0 });
  };

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    const id = setInterval(updatePointer, 50); // Update every 50ms
    setIntervalId(id);

    return () => clearInterval(id);
  }, [joystickData]);

  // const handleMove = (event) => {
  //   const { x, y } = event; // x and y are normalized [-1, 1]

  //   // Determine the movement speed and direction
  //   if (x !== 0 || y !== 0) {
  //     const movementSpeed = 10;
  //     const deltaX = x * movementSpeed;
  //     const deltaY = y * movementSpeed;

  //     // Use the exposed function to update the pointer position
  //     if (window.updatePointerPosition) {
  //       window.updatePointerPosition(deltaX, deltaY);
  //     }
  //   }
  // };

  // const handleStop = () => {
  //   console.log("Stop")
  // }

  const handleTouchStart = (key) => {
    if (key == "RMB") {
      setRMBClicked(true)
      setTimeout(() => {
        let message = {
          id: 'mousebtn',
          type: 'mousedown',
          button: 3
        }
        setMouseBtnMessage(JSON.stringify(message));
      }, 10);
    }
    else if (key == "LMB") {
      setLMBClicked(true)
      setTimeout(() => {
        let message = {
          id: 'mousebtn',
          type: 'mousedown',
          button: 1
        }
        setMouseBtnMessage(JSON.stringify(message));
      }, 10);
    }
  }

  const handleTouchStop = (key) => {
    if (key == "RMB") {
      setTimeout(() => {
        setRMBClicked(false)
        let message = {
          id: 'mousebtn',
          type: 'mouseup',
          button: 3
        }
        setMouseBtnMessage(JSON.stringify(message));
      }, 100)
    } else if (key == "LMB") {
      setTimeout(() => {
        setLMBClicked(false)
        let message = {
          id: 'mousebtn',
          type: 'mouseup',
          button: 1
        }
        setMouseBtnMessage(JSON.stringify(message));
      }, 100)
    }
  }

  return (
    <div style={{ userSelect: "none" }}>
      {/* <div style={{
        // transform: "translate(-50%, -50%)",
        transition: "transform 0.1s ease",
        color: '#111',
        fontSize: '1.3rem',
        position: 'absolute',
        left: mouseStates.mouseX,
        top: mouseStates.mouseY,
        height: '25%',
        width: '25%',
        // border: "2px solid white",
        // borderRadius: "50%",
      }}>
        o
      </div> */}

      <div
        style={{
          position: 'absolute',
          left: pointerPosition.x + marginLeft,
          top: pointerPosition.y + marginTop,
          height: '25px',
          width: '25px',
          // border: '2px solid white',
          // borderRadius: '50%',
          transition: 'transform 0.1s ease',
          transform: 'translate(-50%, -50%)',
          color: '#111',
          fontSize: '1.3rem',
          zIndex: 2,
        }}
      >
        o
      </div>

      <button
        className="mobButtonShape"
        style={{
          height: "15%",
          width: "10%",
          opacity: "50%",
          // backgroundColor: "grey",
          backgroundColor: LMBClicked ? "#fff" : "#171717",
          color: LMBClicked ? "#111" : "#fff",
          position: 'absolute',
          // color: '#22B9A6',
          left: '12%',
          bottom: '10%',
          zIndex: 99
        }}
        onTouchStart={() => handleTouchStart('LMB')}
        onTouchEnd={() => handleTouchStop('LMB')}
      >
        Left Click
      </button>
      <button
        className="mobButtonShape"
        style={{
          // borderRadius: "10px",
          height: "15%",
          width: "10%",
          opacity: "50%",
          // backgroundColor: "grey",
          backgroundColor: RMBClicked ? "#fff" : "#171717",
          color: RMBClicked ? "#111" : "#fff",
          position: 'absolute',
          left: '25%',
          bottom: '10%',
          zIndex: 99
        }}
        onTouchStart={() => handleTouchStart('RMB')}
        onTouchEnd={() => handleTouchStop('RMB')}
      >
        Right Click
      </button>

      <JoystickDiv className='joytsickDiv' style={{ zIndex: 99 }} right="10%" top="70%" id="LJ">
        <Joystick
          size={Math.round(Math.round((window.innerWidth * 9) / 16) * 0.25)}
          stickSize={Math.round(Math.round((window.innerWidth * 9) / 16) * 0.25 * 0.4)}
          baseColor="grey"
          stickColor="#4e4f52"
          move={handleMove} stop={handleStop}
          throttle={50}
          minDistance={Math.round(Math.round((window.innerWidth * 9) / 16) * 0.25 * 0.1)}
        />
      </JoystickDiv>
    </div>
  );
}

export default OnScreenMouse;
