import React from "react"

export default function Disclaimer() {
    return (
        <>
            <section style={{ padding: "3rem 0" }} class="help-section writter-artical-details help-section-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading-innerBanner">
                                <h2 class="section-heading text-center">
                                    <span> Disclaimer </span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div style={{ border: "1px solid #ddd", padding: "4rem 60px 12px", marginTop: "3rem" }} class="help-box">
                                <div class="help-box-inner2 mt-0">

                                    <div class="single-artical">
                                        <div class="content">
                                            <div className="inner-content">
                                                <p>
                                                    Steam©, Valve &amp; Steam software are all property of the Valve Corporation.
                                                    <br /><br />
                                                    Epic, Epic Games, the Epic Games logo, are trademarks or registered trademarks of Epic Games, Inc. in the United States of America and elsewhere.
                                                    <br /><br />
                                                    All trademarks are property of their respective owners in the US and other countries.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}