import React, { useRef } from 'react';

const Parallax = ({ children }) => {
  const parallaxRef = useRef(null);

  // const handleMouseMove = (e) => {
  //   if (parallaxRef.current) {
  //     const offset = parallaxRef.current.getBoundingClientRect();
  //     const xPos = e.pageX - offset.left;
  //     const yPos = e.pageY - offset.top;
  //     const mouseXPercent = Math.round((xPos / parallaxRef.current.offsetWidth) * 80);
  //     const mouseYPercent = Math.round((yPos / parallaxRef.current.offsetHeight) * 80);

  //     const animatedIcons = parallaxRef.current.querySelectorAll('.animated-icon');
  //     animatedIcons.forEach((icon) => {
  //       const diffX = parallaxRef.current.offsetWidth - icon.offsetWidth;
  //       const diffY = parallaxRef.current.offsetHeight - icon.offsetHeight;
  //       const myX = Math.max(0, Math.min(xPos * (mouseXPercent / 1500), diffX));
  //       const myY = Math.max(0, Math.min(yPos * (mouseYPercent / 1080), diffY));
  //       icon.style.marginLeft = myX + 'px';
  //       icon.style.marginTop = myY + 'px';
  //     });
  //   }
  // };

  // const handleMouseLeave = () => {
  //   const animatedIcons = parallaxRef.current.querySelectorAll('.animated-icon');
  //   animatedIcons.forEach((icon) => {
  //     icon.style.marginLeft = "0";
  //     icon.style.marginTop = "0%";
  //   });
  // };

  return (
    <div className="col-lg-4 points-box image-parallax" ref={parallaxRef}
      // onMouseMove={handleMouseMove}
      // onMouseLeave={handleMouseLeave}
      >
      {children}
    </div>
  );
};

export default Parallax;
