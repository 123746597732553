import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
// import ContactUs from './Pages/ContactUs';
import Download from './Pages/Download';
import Faq from './Pages/Faq';
import ForgotPassword from './Pages/ForgotPassword';
import Pricing from './Pages/Pricing';
import SignIn from './Pages/Signin';
import SignUp from './Pages/Signup';
import StreamPage from './Pages/Stream';
import AdminStream from './Pages/AdminStream';
import Support from './Pages/Support';
import Testimonials from './Pages/Testimonials';
import Header from './Components/Header';

import Aos from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-toastify/dist/ReactToastify.css';
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Footer from './Components/Footer';
import { AuthProvider } from './AuthContext';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import ThankYou from './Pages/ThankYou';
import NotFound from './Pages/NotFound';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TnC from './Pages/TnC';
import EmailVerify from './Pages/emailVerify';
import CancellationPolicy from './Pages/CancellationPolicy';
import ShippingPolicy from './Pages/ShippingPolicy';
import MobilePricing from './Pages/mobilePricing';
import Blogdetail from './Components/Blogs/BlogDeatil';
import BlogDashboard from './Components/Blogs/BlogDashboard';
import Maintenance from './Components/Maintenance';
import HelmetProvider from './HelmetProvider';
import Disclaimer from './Pages/Disclaimer';
import ControllerMapper from './Pages/ControllerMapping';
import ResetPassword from './Pages/ResetPassword';
import Test from './Pages/test';
import WindowsStream from './Pages/WindowsStream';
import Instructions from './Pages/Instructions';

function App() {
  Aos.init({
    easing: 'ease-out-back',
    duration: 1000,
    once: true
  });
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const query = location.search;
    if (location.pathname !== "/stream" && location.pathname !== "/windows/stream" && location.pathname !== "/test" && location.pathname !== "/adminstream" && location.pathname !== "/mobilePricing" && !(location.pathname === "/controllerMapping" && query.includes('type=mobile'))) setShowHeader(true)
    else setShowHeader(false);


    if (location.pathname === "/signup" && query && query.includes("source=SAM")) {
      let tvCode = query.split("&code=")[1];
      const start = new Date()
      localStorage.setItem("_antCQR", `ANTCQR^${tvCode}^${start.valueOf()}`);
    }

    if (location.pathname === "/mobilePricing" || (location.pathname === '/stream' && query.includes("type=mobile")) || (location.pathname === "/controllerMapping" && query.includes('type=mobile'))) setShowFooter(false)
    else setShowFooter(true)

    window.scrollTo(0, 0);
  }, [location.pathname])

  return (
    <>
      <HelmetProvider />
      {/* <Header /> */}
      <AuthProvider>
      {/* <Maintenance /> */}
        {showHeader ? <Maintenance /> : <></>}
        {showHeader ? <Header /> : <></>}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/contact" element={<ContactUs />} /> */}
          <Route path="/download" element={<Download />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/support" element={<Support />} />
          <Route path="/windows/guide" element={<Instructions />} />
          <Route path='/stream' element={<StreamPage />} />
          <Route path='/windows/stream' element={<WindowsStream />} />
          <Route path='/adminstream' element={<AdminStream />} />
          <Route path='/controllerMapping' element={<ControllerMapper />} />
          {/* <Route path="/testimonials" element={<Testimonials />} /> */}
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/tnc' element={<TnC />} />
          {/* <Route path='/androidtest' element={<Test />} /> */}
          <Route path='/cancellationPolicy' element={<CancellationPolicy />} />
          <Route path='/shippingPolicy' element={<ShippingPolicy />} />
          <Route path='/thankyou' element={<ThankYou />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/mobilePricing' element={<MobilePricing />} />
          <Route path='/verify/email' element={<EmailVerify />} />
          <Route path="/blogdetail/:id" element={<Blogdetail />} />
          <Route path="/blogdashboard" element={<BlogDashboard />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        {showFooter ? <Footer /> : <></>}

        <ToastContainer
          newestOnTop
          autoClose={5000}
          theme='dark'
        />
      </AuthProvider>
    </>
  );
}

export default App;
